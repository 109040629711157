<template>
  <div id="shipping" style="text-align:left;">
      <div style="font-size:18px;padding:20px 20px 8px 20px;border-bottom:1px solid #E4E7ED">Pickup Location</div>
       <div style="padding:20px;background-color:#E4E7ED;margin-top:8px;">
           <div style="font-size:16px;">
               <el-select v-model="expectedLocation" placeholder="choose location">
                    <el-option v-for="item in selectLocations" :key="item.ID" :label="item.name" :value="item.ID"></el-option>
                </el-select>
           </div>
           <div v-show="expectedLocation > 0">
             <div class="address-item">{{client.name}}</div>
             <div class="address-item">{{client.contact_no}}</div>
             <div class="address-item">{{locationName}}</div>
             <div class="address-item">{{locationAddress}}</div>
             <div class="address-item">Singapore, {{locationPostalCode}}</div>
           </div>
       </div>
       <div style="padding:20px;background-color:#E4E7ED;margin-top:8px;">
           <div>Pickup Date</div>
           <div class="address-item">
                <el-date-picker v-model="expectedShippingDate" type="date" placeholder="Choose Date" value-format="yyyy-MM-dd" :clearable="false" :picker-options="pickerOptions"></el-date-picker>
                <!--<el-time-select v-model="expectedShippingTime" :picker-options="timeOptons" placeholder="Choose Time" :clearable="false"></el-time-select>-->
           </div>
       </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
    name: 'shipping',
    computed: {
        ...mapState('client', [
            'client'
        ]),
        ...mapState('cart', [
            'shipping'
        ]),
        ...mapState('util', [
            'locations'
        ]),
        locationName: function(){
            if(this.shipping.location == null){
                return 'Office Name'
            }
            return this.shipping.location.name
        },
        locationAddress: function(){
            if(this.shipping.location == null){
                return 'Office Address'
            }
            return this.shipping.location.address
        },
        locationPostalCode: function(){
            if(this.shipping.location == null){
                return 'Postal Code'
            }
            return this.shipping.location.postal_code
        },
        expectedLocation:{
            get(){
                return this.shipping.locationId
            },
            set(val){
                var selectLocation = this.locations[0]
                this.locations.forEach(item => {
                    if(item.ID === val){
                        selectLocation = item
                    }
                });
                this.setShipping({
                    expectedShippingDate: '',
                    expectedShippingTime: '',
                    location: selectLocation
                })
            }
        },
        expectedShippingDate:{
            get(){
                return this.shipping.expectedShippingDate
            },
            set(val){
                if(val == null){
                    val = ''
                }
                this.setShipping({
                    location: null,
                    expectedShippingDate: val,
                    expectedShippingTime: ''
                })
            }
        },
        expectedShippingTime: {
            get(){
                return this.shipping.expectedShippingTime
            },
            set(val){
                if(val == null){
                    val = ''
                }
                this.setShipping({
                    location: null,
                    expectedShippingDate: '',
                    expectedShippingTime: val
                })
            }
        }
    },
    data(){
        return {
            timeOptons:{
                start: '09:00',
                step: '00:30',
                end: '18:00'
            },
            pickerOptions:{
                disabledDate:(date)=>{
                    var now = new Date().getTime()
                    var delta = date.getTime() - now 
                    return delta < 0 || delta >= 7 * 24 * 3600 * 1000
                }
            },
            selectLocations: []
        }
    },
    mounted(){
        this.getLocations().then(()=>{
            this.selectLocations.push({
                ID: 0,
                name: 'Not Select'
            })
            this.selectLocations.push(...this.locations)
        })
    },
    methods:{
        ...mapActions('util', [
            'getLocations'
        ]),
        ...mapActions('cart', [
            'setShipping'
        ])
    }
}
</script>

<style>
.address-item{
    padding: 8px 0px;
    font-size: 14px;
}
</style>