<template>
  <div id="merchant">
      <el-card>
          <div slot="header" style="text-align:right;">
            <el-button type="danger" @click="createMerchantClick">Create Merchant</el-button>
            <el-table :data="merchants" v-loading="loading">
                <el-table-column label="Name" prop="name"></el-table-column>
                <el-table-column label="Contact Person" prop="contact_person"></el-table-column>
                <el-table-column label="Email" prop="email"></el-table-column>
                <el-table-column label="Contact Phone" prop="contact_no"></el-table-column>
                <el-table-column label="Deliver">
                    <template slot-scope="scope">
                        <el-button @click="handleDeliver(scope.row)" type="text" size="small">View</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="enable" label="Enable">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.enable" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="Action" width="120">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small" style="margin-right:20px;">Edit</el-button>
                        <el-popconfirm confirm-button-text="Delete" @confirm="deleteMerchantClick(scope.row)" cancel-button-text="Cancel" icon="el-icon-info" icon-color="red" :title="'Delete Merchant ' + scope.row.name + '?'">
                        <el-button slot="reference" type="text" size="small">Delete</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </div>
      </el-card>
      <el-drawer :show-close="false" :title="drawerTitle" :visible.sync="showDrawer" direction="rtl" style="text-align:left;" :before-close="handleClose" ref="drawer">
          <el-form :model="editForm" style="padding:10px;" ref="editForm" :rules="rules">
              <el-form-item label="Name" prop="name">
                  <el-input v-model="editForm.name"></el-input>
              </el-form-item>
              <el-form-item label="Contact Person" prop="contact_person">
                  <el-input v-model="editForm.contact_person"></el-input>
              </el-form-item>
              <el-form-item label="Contact Phone" prop="contact_no">
                  <el-input v-model="editForm.contact_no"></el-input>
              </el-form-item>
              <el-form-item label="Email" prop="email">
                  <el-input type="email" v-model="editForm.email"></el-input>
              </el-form-item>
              <el-form-item label="Email CC" prop="email_cc">
                  <el-input v-model="editForm.email_cc"></el-input>
              </el-form-item>
              <el-form-item label="Brand Image" prop="brand_image">
                  <el-input v-model="editForm.brand_image"></el-input>
              </el-form-item>
              <el-form-item label="Website URL" prop="website">
                  <el-input v-model="editForm.website"></el-input>
              </el-form-item>
              <el-form-item label="Location" prop="location">
                  <el-input v-model="editForm.location"></el-input>
              </el-form-item>
              <el-form-item label="Enable" prop="enable">
                  <el-switch v-model="editForm.enable" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
              </el-form-item>
          </el-form>
          <div style="padding:10px;">
            <el-button @click="cancelForm" style="width:48%">Cancel</el-button>
            <el-button type="primary" style="width:48%" @click="saveButtonClick" :loading="loading">{{ loading ? 'submit ...' : 'Save' }}</el-button>
          </div>
      </el-drawer>
      <el-dialog title="Deliver Info" :visible.sync="showDialog">
          <el-form :model="deliver">
              <el-form-item label="Free For Order Over $">
                  <el-input v-model="deliver.free_fee"  oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
              </el-form-item>
              <el-form-item label="Normal Charge Fee $">
                  <el-input v-model="deliver.fee" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
              </el-form-item>
              <el-form-item label="Book In Advance Days">
                  <el-input v-model="deliver.pre_order_day" type="number"></el-input>
              </el-form-item>
              <el-form-item label="Deliver Service Desc">
                  <el-input v-model="deliver.deliver_service"></el-input>
              </el-form-item>
          </el-form>
          <div slot="footer">
              <el-button @click="showDialog=false">Cancel</el-button>
              <el-button type="primary" @click="saveDeliverClick">Save Changes</el-button>
          </div>
      </el-dialog>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
    name: 'merchant',
    data(){
        return {
            loading: false,
            action: '',
            showDrawer: false,
            showDialog: false,
            drawerTitle: 'Create Merchant',
            deliver: {
                id: 0,
                merchant_id: 0,
                free_fee: 0,
                fee: 0,
                pre_order_day: 0,
                deliver_service: ''
            },
            editForm: {
                id: 0,
                name: '',
                email: '',
                email_cc: '',
                contact_no: '',
                contact_person: '',
                location: '',
                brand_image: '',
                website: '',
                enable: true 
            },
            rules:{
                name: [
                    {required: true, messsge: 'name required', trigger: 'blur'}
                ],
                email: [
                    {type: 'email', required: false, trigger: 'blur'}
                ]
            }
        }
    },
    computed: {
        ...mapState('merchant', [
            'merchants'
        ])
    },
    mounted(){
        this.list()
    },
    methods: {
        ...mapActions('merchant', [
            'createMerchant',
            'getMerchantList',
            'deleteMerchant',
            'updateMerchant',
            'saveDeliver'
        ]),
        list(){
            this.loading = true
            this.getMerchantList().then(()=>{
                this.loading = false
            }).catch(()=>{
                this.loading = false
            })
        },
        createMerchantClick(){
            this.showDrawer = true
            this.drawerTitle = "Create Merchant"
            this.action = 'create'
            this.editForm.name = ''
            this.editForm.id = 0
            this.editForm.email = ''
            this.editForm.email_cc = ''
            this.editForm.contact_no = ''
            this.editForm.contact_person = ''
            this.editForm.location = ''
            this.editForm.brand_image = ''
            this.editForm.website = ''
            this.editForm.enable = true 
        },
        handleClick(row){
            console.log(row)
            this.showDrawer = true 
            this.drawerTitle = "Modify Merchant"
            this.action = 'edit'
            this.editForm.name = row.name
            this.editForm.email = row.email
            this.editForm.email_cc = row.email_cc
            this.editForm.contact_person = row.contact_person
            this.editForm.contact_no = row.contact_no
            this.editForm.location = row.location
            this.editForm.brand_image = row.brand_image
            this.editForm.website = row.website
            this.editForm.enable = row.enable
            this.editForm.id = row.ID
        },
        deleteMerchantClick(row){
            console.log(row)
            this.deleteMerchant({
                id: row.ID
            }).then(()=>{
                this.$message({
                    message: 'delete successfuly',
                    type: 'success'
                })
            })
        },
        handleClose(done){
            done()
        },
        saveButtonClick(){
            this.$refs['editForm'].validate((valid)=>{
                console.log(valid)
                if(valid){
                    if(this.action === 'create'){
                        this.createMerchant({
                            merchant:{
                                name: this.editForm.name,
                                email: this.editForm.email,
                                email_cc: this.editForm.email_cc,
                                contact_no: this.editForm.contact_no,
                                contact_person: this.editForm.contact_person,
                                location: this.editForm.location,
                                brand_image: this.editForm.brand_image,
                                website: this.editForm.website,
                                enable: this.editForm.enable
                            }
                        }).then(()=>{
                            this.$message(({
                                message: 'Save successfully',
                                type: 'success'
                            }))
                            this.showDrawer = false
                        })
                    }else if(this.action === 'edit'){
                    console.log('save edit')
                    this.updateMerchant({
                        merchant:{
                            ID: this.editForm.id, 
                            name: this.editForm.name,
                            email: this.editForm.email,
                            email_cc: this.editForm.email_cc,
                            contact_no: this.editForm.contact_no,
                            contact_person: this.editForm.contact_person,
                            location: this.editForm.location,
                            brand_image: this.editForm.brand_image,
                            website: this.editForm.website,
                            enable: this.editForm.enable
                        }
                        }).then(()=>{
                            this.$message(({
                                message: 'Save successfully',
                                type: 'success'
                            }))
                            this.showDrawer = false
                        })
                    }
                }  else {
                    return false
                }
            })
        },
        cancelForm() {
            this.loading = false
            this.showDrawer = false
        },
        handleDeliver(row) {
            console.log(row)
            this.deliver.ID = row.deliver.ID
            this.deliver.merchant_id = row.ID
            this.deliver.free_fee = row.deliver.free_fee / 100.0
            this.deliver.fee = row.deliver.fee / 100.0
            this.deliver.pre_order_day = row.deliver.pre_order_day
            this.deliver.deliver_service = row.deliver.deliver_service
            this.showDialog = true
        },
        saveDeliverClick() {
            this.showDialog = false
            console.log(this.deliver.fee)
            console.log(this.deliver.fee * 100)
            var cp = {
                ID: this.deliver.ID,
                merchant_id: this.deliver.merchant_id,
                free_fee:  Math.ceil(this.deliver.free_fee * 100.),
                fee: Math.ceil(this.deliver.fee * 100.),
                pre_order_day: this.deliver.pre_order_day * 1,
                deliver_service: this.deliver.deliver_service
            }
            this.saveDeliver({
                deliver: cp
            })
        }
    }
}
</script>

<style>

</style>