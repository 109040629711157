<template>
  <el-row id="checkoutItem">
      <el-col :span="24" v-show="showLarge"  class="checkout-item">
        <el-image :src="item.product.image" style="width:80px;height:80px;"></el-image>
        <div style="margin-left:20px;text-align:left;-webkit-box-flex:1;">
            <div style="font-size:18px;">{{item.product.name}}</div>
            <div style="margin-top:10px;"><span v-show="!isMember">${{(item.sku.price/100.0).toFixed(2)}}</span><span v-show="isMember">${{(item.sku.member_price/100.0).toFixed(2)}}</span> For 1 {{item.sku.name}}</div>
            <div style="margin-top:10px;font-size:12px;color:#909399;">{{item.product.merchant.name}}</div>
        </div>
        <div style="width:120px;text-align:right;">
            {{item.amount}}
        </div>
        <div style="text-align:right;width:120px;">
            <span v-show="!isMember">${{(item.sub_total/100.0).toFixed(2)}}</span>
            <span v-show="isMember">${{(item.sub_member_total/100.0).toFixed(2)}}</span>
        </div>
      </el-col>
      <el-col :span="24" v-show="!showLarge" class="checkout-item-small">
          <el-image :src="item.product.image" style="width:80px;height:80px;"></el-image>
          <div style="margin-left:10px;margin-right:10px;text-align:left;-webkit-box-flex:1;">
            <div style="font-size:14px;">{{item.product.name}}</div>
            <div style="margin-top:10px;font-size:12px;"><span v-show="!isMember">${{(item.sku.price/100.0).toFixed(2)}}</span><span v-show="isMember">${{(item.sku.member_price/100.0).toFixed(2)}}</span> For 1 {{item.sku.name}}</div>
            <div style="text-align:right; font-size:12px;">Amount: {{item.amount}}</div>
            <div style="text-align:right; font-size:12px;">Fee: 
                <span v-show="!isMember">${{(item.sub_total/100.0).toFixed(2)}}</span>
                <span v-show="isMember">${{(item.sub_member_total/100.0).toFixed(2)}}</span>
            </div>
        </div>
      </el-col>
  </el-row>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'checkoutItem',
    props: ['item', 'view'],
    computed: {
        ...mapState('client', [
            'client'
        ]),
        isMember: function () {
            return this.client.is_member
        },
        showLarge: function () {
            return this.view === 'large'
        }
    }
}
</script>

<style>
.checkout-item {
    display:-webkit-box;
    padding: 10px 20px;
    border-bottom: 1px solid #fff;
}
.checkout-item-small {
    display: -webkit-box;
}
</style>