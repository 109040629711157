<template>
  <el-container class="home">
    <el-header v-if="showHeader" style="padding:0px; position:relative;">
      <shop-header></shop-header>
    </el-header>
    <el-main v-if="showHeader" style="position:absolute;top:110px;bottom:0;overflow-y:scroll;left:0;right:0;padding:0;">
      <component :is="view"></component>
    </el-main>
    <el-main v-else style="position:absolute;top:10px;bottom:0;overflow-y:scroll;left:0;right:0;padding:0;">
      <component :is="view"></component>
    </el-main>
  </el-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
// @ is an alias to /src
import ShopHeader from '@/components/ShopHeader.vue'
import Login from '@/components/Login.vue'
import Admin from '@/components/Admin.vue'
import Index from '@/components/Index.vue'
import Checkout from '@/components/Checkout.vue'
import ClientOrder from '@/components/ClientOrder.vue'
import personalInfo from '@/components/PersonalInfo.vue'
import HotelView from '@/components/HotelView.vue'
import UserBookList from '@/components/UserBookList.vue'
import CartView from '@/components/CartView.vue'

export default {
  name: 'Home',
  components: {
    ShopHeader,
    Login,
    Admin,
    Index,
    Checkout,
    ClientOrder,
    personalInfo,
    HotelView,
    UserBookList,
    CartView
  },
  data(){
    return {
       msg: '',
       showHeader: true
    }
  },
  computed:{
    ...mapState('client', [
      'view'
    ])
  },
  created () {
    if (this.$route.query) {
      console.log(this.$headers)
      var action = this.$route.query.action
      if (action === 'mlogin') {
        this.showHeader = false
        var goto = this.$route.query.goto
        var id = this.$route.query.id
        console.log(goto, id)
        this.mLogin({
          id: id,
        }).then(()=>{
          var view = 'HotelView'
          if(goto === 'shop') {
            view = 'Index'
          } else if (goto === 'CartView') {
            view = 'CartView'
          }
          this.setView({
            view: view
          })
        })
      }
    }
  },
  methods: {
    ...mapActions('client', ['mLogin', 'setView'])
  }
}
</script>

<style>
.home {
  padding: 0px;
}
</style>