<template>
  <el-main id="shopHeader" style="padding:20px;">
      <el-row class="mid-row">
          <el-col :span="2"  :md="3" :sm="4" :xs="8" style="text-align:left;"><el-image fit="contain" class="logo-img" :src="require('../assets/uc_logo.png')" style="cursor:pointer;" @click="homePageClick"></el-image></el-col>
          <el-col :span="4"  :md="4" :sm="6" :xs="0" style="text-align:left;font-size:36px;font-weight:500;line-height:60px;color:#221915;cursor:pointer;" @click="homePageClick">U-Shop</el-col>
          <el-col :span="18" :md="17" :sm="14" :xs="16" style="text-align:right;">
              <el-badge v-show="showCart" :value="cartCount">
                <el-button icon="el-icon-shopping-cart-1" type="text" style="font-size:18px;margin-top:-5px;" @click="cartClick"><span v-show="isMember">{{cartMemberTotal}}</span><span v-show="!isMember">{{cartTotal}}</span></el-button>
              </el-badge>
              <el-button  v-if="token.length == 0" @click="userBtnClick"  icon="el-icon-user-solid user-icon" type="text" style="margin-left:10px; margin-top:10px;font-size:18px;">
                    <span>Login</span>
              </el-button>
              <el-popover v-else placement="bottom" trigger="click">
                  <div>
                      <el-button type="text" @click="orderListClick">Order List</el-button>
                  </div>
                  <div>
                      <el-button type="text" @click="bookListClick">Hotel Book List</el-button>
                  </div>
                  <div>
                      <el-button type="text" @click="personalInfoClick">Peronsonal Info</el-button>
                  </div>
                  <div v-if="client.c_type==='admin'">
                      <el-button type="text" @click="adminClick">Admin</el-button>
                  </div>
                  <div>
                      <el-button @click="logoutClick" type="text">Logout</el-button>
                  </div>
                  <el-button slot="reference" icon="el-icon-user-solid user-icon" type="text" style="margin-left:10px;margin-top:10px;font-size:18px;">
                  </el-button>
              </el-popover>
          </el-col>
      </el-row>
      <el-drawer :visible.sync="showDrawer" title="Cart" direction="rtl" size="375px">
          <div style="border-top:1px solid #E4E7ED;height:8px;width:100%;text-align:right;font-size:12px;padding-bottom:10px;padding-right:8px;"><span v-show="isMember" style="color:#F56C6C;">Membership: Yes</span><span v-show="!isMember"  style="color:#409EFF;">Membership: No</span></div>
          <div>
            <cart-item v-for="good in cartGoodList" :key="good.key" :item="good"></cart-item>
          </div>
          <div>
            <div v-show="isMember" style="text-align:right;font-size:16px;padding:8px;">
                Total Price：&nbsp;<span style="color:#F56C6C">{{cartMemberTotal}} <span style="font-size:12px;color:#409EFF;text-decoration:line-through;">{{cartTotal}}</span></span>
            </div>
            <div v-show="!isMember" style="text-align:right;font-size:16px;padding:8px;">
                Total Price：&nbsp;<span style="color:#409EFF">{{cartTotal}}</span>
            </div>
            <div style="text-align:right;font-size:12px;padding:0px 8px 8px 8px;color:#909399;">
                Total Amount：&nbsp;<span>{{cartCount}}</span>
            </div>
            <el-row style="padding:8px;">
                <el-col :span="12"><el-button type="danger" style="width:98%;" @click="clearCartClick">Clear</el-button></el-col>
                <el-col :span="12"><el-button type="primary" style="width:98%;" @click="checkoutClick">Checkout</el-button></el-col>
            </el-row>
            <br/>
          </div>
      </el-drawer>
  </el-main>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import _ from 'lodash'
import CartItem from './CartItem.vue'

export default {
    name: 'shopHeader',
    components:{
        CartItem
    },
    data(){
        return {
            showDrawer: false
        }
    },
    computed:{
        ...mapState('client', [
            'client',
            'token',
            'tokenTime'
        ]),
        ...mapState('cart', [
            'goodList'
        ]),
        isMember: function() {
            if (this.client === null) {
                return false
            }
            return this.client.is_member
        },
        cartTotal: function(){
           var total = 0 
           if(this.goodList.length == 0) {
               return '$0.00'
           }
           for(var i=0; i < this.goodList.length; i++){
               total += this.goodList[i].sku.price * this.goodList[i].amount
           }
           console.log(this.goodList)
           return '$' + (total/100.0).toFixed(2)
        },
        cartMemberTotal: function(){
           var total = 0 
           if(this.goodList.length == 0) {
               return '$0.00'
           }
           for(var i=0; i < this.goodList.length; i++){
               total += this.goodList[i].sku.member_price * this.goodList[i].amount
           }
           console.log(this.goodList)
           return '$' + (total/100.0).toFixed(2)
        },
        cartCount: function(){
            var count = 0
            for(var i=0; i < this.goodList.length; i++){
                count += this.goodList[i].amount
            }
            return count
        },
        cartGoodList: function(){
            var list = _.cloneDeep(this.goodList)
            return list
        },
        showCart: function(){
            if(this.token.length > 0){
                if(this.client.c_type === 'admin'){
                    return false
                }
            }
            return true 
        }
    },
    methods: {
        ...mapActions('client',[
            'logout',
            'setView',
            'checkout'
        ]),
        ...mapActions('cart', [
            'clearGoods',
            'resetShipping'
        ]),
        ...mapActions('category', [
            'clearCatgories'
        ]),
        ...mapActions('merchant', [
            'clearMerchants'
        ]),
        ...mapActions('product', [
            'clearProducts'
        ]),
        ...mapActions('order', [
            'clearOrders'
        ]),
        cartClick() {
            this.showDrawer = true
            // this.setView({view: 'CartView'})
        },
        homePageClick() {
            window.location.reload()
            this.setView({
                view: 'index'
            })
        },
        userBtnClick(){
            this.setView({
                view: 'login'
            })
        },
        logoutClick(){
            this.clearGoods()
            this.clearOrders()
            this.logout()
        },
        clearCartClick(){
            this.clearGoods()
        },
        orderListClick(){
            this.setView({
                view: 'clientOrder'
            })
        },
        bookListClick(){
            this.setView({
                view: 'UserBookList'
            })
        },
        personalInfoClick(){
            this.setView({
                view: 'personalInfo'
            })
        },
        adminClick(){
            this.setView({
                view: 'admin'
            })
        },
        checkoutClick(){
            if(this.cartCount == 0){
                return
            }
            if(this.token.length == 0){
                this.$message({
                    message: 'Login is required before checkout',
                    type: 'warning'
                });
                return
            } else {
                console.log(this.tokenTime)
                var ts = new Date().getTime() - this.tokenTime
                console.log(ts)
                if(ts / 1000 > 3600 * 24 * 3) {
                    this.$message({
                        message: 'Your session is expired, need to login again',
                        type: 'warning'
                    });
                    this.setView({
                        view: 'login'
                    })
                    return
                }
            }
            this.showDrawer = false;
            this.resetShipping()
            this.checkout()
        }
    }
}
</script>

<style>
#shopHeader {
    background-color: #F2F6FC;
    width: 100%;
}
.logo-img {
    margin-top:5px;
    width:100px;
}
.mid-row {
    margin: 0 auto;
}
.header-title {
    font-size: 24px;
    line-height: 60px;
}
.login-btn {
    width:60px;
    margin-top:10px;
}
.el-drawer.rtl{
    overflow-y: scroll;
}
</style>