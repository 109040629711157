<template>
  <div id="clientOrder" style="max-width:1024px; margin: 40px auto; background-color:#fff;padding:20px; border:1px solid #DCDFE6">
      <el-container>
          <el-header style="background-color:#E4E7ED; height:48px; border-radius:5px; line-height:48px; text-align:left; font-size:18px;">
            <el-link type="primary" :underline="false" style="font-size:18px;" @click="setView({view:'index'})">Home</el-link>
            <el-link type="info" :underline="false" style="margin: 0px 5px; font-size:18px;">/</el-link>
            <el-link type="info" :underline="false" style="font-size:18px;">Order List</el-link>
          </el-header>
          <el-main style="padding:0px;">
              <client-order-item v-for="item in clientOrderList" :key="item.ID" :item="item"></client-order-item>
              <br/>
              <el-pagination background layout="prev, pager, next" @current-change="changeCurrentPage" :total="clientOrderCount" :page-size="pageSize" :current-page="pageIndex">
              </el-pagination>
          </el-main>
      </el-container>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import ClientOrderItem from './ClientOrderItem.vue'

export default {
    name: 'clientOrder',
    components:{
        ClientOrderItem,
    },
    computed:{
        ...mapState('order', [
            'clientOrderList',
            'clientOrderCount',
            'pageSize',
            'pageIndex'
        ])
    },
    mounted(){
        this.getClientOrderList()
    },
    methods: {
        ...mapActions('client', [
            'setView'
        ]),
        ...mapActions('order', [
            'getClientOrderList',
            'setCurrentIndex'
        ]),
        changeCurrentPage(val){
            this.setCurrentIndex({
                pageIndex: val
            })
        }
    }
}
</script>

<style>

</style>