<template>
  <div id="userBookView" style="max-width:1024px; margin: 40px auto; background-color:#fff;padding:20px; border:1px solid #DCDFE6">
      <el-container>
          <el-header style="background-color:#E4E7ED; height:48px; border-radius:5px; line-height:48px; text-align:left; font-size:18px;">
            <el-link type="primary" :underline="false" style="font-size:18px;" @click="setView({view:'index'})">Home</el-link>
            <el-link type="info" :underline="false" style="margin: 0px 5px; font-size:18px;">/</el-link>
            <el-link type="info" :underline="false" style="font-size:18px;">Hotel Book List</el-link>
          </el-header>
          <el-main style="padding:0px;margin-top:10px;">
              <el-card v-for="book in clientBookList" :key="book.ID" style="background-color:#E4E7ED;">
                  <el-row style="text-align:left;font-weight:bold;background-color:#E4E7ED;">
                      <el-col :span="12" :xs="24">Order Number: {{book.ID}}</el-col>
                      <el-col :span="12" :xs="0" style="text-align:right;">Date: {{createdDate(book.CreatedAt)}}</el-col>
                      <el-col :span="12" :xs="24" :sm="0" :md="0" :xl="0" :lg="0" style="text-align:left;">Date: {{createdDate(book.CreatedAt)}}</el-col>
                  </el-row>
                  <el-divider></el-divider>
                  <el-row style="text-align:left;margin-top:0px;">
                      <el-col :span="24"><span style="font-weight:bold;font-size:14px;">Hotel:</span> {{book.hotel_name}}</el-col> 
                      <el-col :span="12" :xs="0" style="margin-top:10px;"><span style="font-weight:bold;font-size:14px;">Contact Number:</span> {{book.hotel_contact_number}}</el-col>
                      <el-col :span="12" :xs="0" style="text-align:right;"><span style="font-weight:bold;font-size:14px;">Email:</span> {{book.hotel_contact_email}}</el-col>
                      <el-col :span="12" :xs="24" :sm="0" :md="0" :xl="0" :lg="0" style="margin-top:10px;"><span style="font-weight:bold;font-size:14px;">Contact Number:</span> {{book.hotel_contact_number}}</el-col>
                      <el-col :span="12" :xs="24" :sm="0" :md="0" :xl="0" :lg="0" style="text-align:left;margin-top:10px;"><span style="font-weight:bold;font-size:14px;">Email:</span> {{book.hotel_contact_email}}</el-col>
                      <el-col :span="24" style="text-align:left;margin-top:10px;"><span style="font-weight:bold;font-size:14px;">Address:</span> {{book.hotel_address}}</el-col>
                  </el-row>
                  <el-row style="text-align:left;">
                      <el-col :span="6" :xs="24" style="margin-top:10px;"><span style="font-weight:bold;font-size:14px;">Room:</span> {{book.room_name}}</el-col> 
                      <el-col :span="6" :xs="0">.</el-col>
                      <el-col :span="6" :xs="12" style="text-align:left;margin-top:10px;"><span style="font-weight:bold;font-size:14px;">From:</span> {{book.date_from}}</el-col>
                      <el-col :span="6" :xs="12" style="text-align:right;margin-top:10px;"><span style="font-weight:bold;font-size:14px;">To:</span> {{book.date_to}}</el-col>
                  </el-row>
                  <el-row style="text-align:left;margin-top:10px;">
                      <el-col :span="6" :xs="24"><span style="font-weight:bold;font-size:14px;">Room Type:</span> {{book.room_type_price_name}}</el-col>
                      <el-col :span="6">&nbsp; .</el-col>
                      <el-col :span="6" :xs="24" style="text-align:left;"><span style="font-weight:bold;font-size:14px;">Weekday Price(S$):</span> {{book.room_type_week_day_price}}</el-col> 
                      <el-col :span="6" :xs="0" style="text-align:right;"><span style="font-weight:bold;font-size:14px;">Weekend Price(S$):</span> {{book.room_type_week_end_price}}</el-col>
                      <el-col :span="6" :xs="24" :sm="0" :md="0" :xl="0" :lg="0" style="text-align:left;margin-top:10px;"><span style="font-weight:bold;font-size:14px;">Weekend Price(S$):</span> {{book.room_type_week_end_price}}</el-col>
                  </el-row>
              </el-card>
              <br/>
              <el-pagination background layout="prev, pager, next" @current-change="changeCurrentPage" :total="clientBookCount" :page-size="pageSize" :current-page="pageIndex">
              </el-pagination>
          </el-main>
      </el-container>
  </div>
</template>

<script>
import hotelApi from '../api/hotel'
import util from '../js/util'
import { mapState, mapActions } from 'vuex'
export default {
    name: 'userbookView',
    data () {
        return {
            clientBookCount: 0,
            clientBookList: [],
            pageSize: 20,
            pageIndex: 1
        }
    },
    computed: {
        ...mapState('client', ['token']),
    },
    mounted(){
        this.getUserBookList()
    },
    methods: {
        ...mapActions('client', [
            'setView'
        ]),
        createdDate(createdAt){
            var date = new Date(createdAt)
            return util.dateFormat("YYYY-mm-dd HH:MM:SS", date)
        },
        changeCurrentPage(val){
            console.log(val)
            this.pageIndex = val
            this.getUserBookList()
        },
        getUserBookList(){
            hotelApi.userBookList(this.pageIndex,this.pageSize, this.token).then(data=>{
                this.clientBookList = data.items
                this.clientBookCount = data.count
            })
        }
    }
}
</script>

<style>

</style>