<template>
  <div id="personalInfo" style="max-width:1024px; margin: 40px auto; background-color:#fff;padding:40px; border:1px solid #DCDFE6">
       <el-container>
          <el-header style="background-color:#E4E7ED; height:48px; border-radius:5px; line-height:48px; text-align:left; font-size:18px;">
            <el-link type="primary" :underline="false" style="font-size:18px;" @click="setView({view:'index'})">Home</el-link>
            <el-link type="info" :underline="false" style="margin: 0px 5px; font-size:18px;">/</el-link>
            <el-link type="info" :underline="false" style="font-size:18px;">Peronal Info</el-link>
          </el-header>
          <el-main style="padding:0px;">
              <el-card style="text-align:left;margin:20px;">
                  <div style="display:-webkit-box;">
                    <div>
                      <el-avatar size="large" :src="require('../assets/avatar.png')"></el-avatar>
                    </div>
                    <div style="margin-left:8px;">
                      <div>{{clientInfo.name}}</div>
                      <div>{{clientInfo.contact_no}}/{{clientInfo.email}}</div>
                    </div>
                  </div>
                  <div style="margin:10px 0px;"><span style="font-weight:bold;">Company:</span> {{clientInfo.company_name}}</div>
                  <div style="margin:10px 0px;"><span style="font-weight:bold;">Location:</span> {{clientInfo.location.name}}</div>
                  <div style="margin:10px 0px;"><span style="font-weight:bold;">Member:</span> {{memberShip}}<el-button v-show="!clientInfo.is_member" @click="joinClick" type="text" size="large">Join Member</el-button></div>
              </el-card>
          </el-main>
      </el-container>
  </div>
</template>

<script>
import userApi from '../api/user'
import {mapState, mapActions} from 'vuex'
import _ from 'lodash'

export default {
    name: 'personalInfo',
    computed: {
        ...mapState('client', [
            'client',
            'token'
        ]),
        clientInfo: function(){
          console.log(this.client)
            var info = _.cloneDeep(this.client)
            return info
        },
        memberShip: function(){
          if(this.clientInfo.is_member === 1){
            return 'Ucommune Member'
          }
          return ''
        }
    },
    methods:{
      ...mapActions('client', [
        'setView',
        'refreshClient'
      ]),
      joinClick(){
        this.$prompt('Please Input Invite Code', 'Join Member', {
          confirmButtonText: 'Join',
          cancelButtonText: 'Cancel'
        }).then(({value})=>{
          console.log(value)
          if (value == null || value.length == 0){
            return
          }
          userApi.joinMember(value, this.token).then(data=>{
            this.refreshClient({
              client:data
            })
          }).catch(err=>{
            if(err.message == 'USED_CODE') {
              this.$message.error('Invite Code Has Been Used')
            } else {
              this.$message.error('Invite Code Error')
            }
          })
        })
      }
    }
}
</script>

<style>

</style>