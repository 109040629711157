<template>
  <div id="cartView">
    <div style="border-top:1px solid #E4E7ED;height:8px;text-align:right;font-size:12px;padding-bottom:10px;margin-right:8px;">
        <span v-show="isMember" style="color:#F56C6C;">Membership: Yes</span><span v-show="!isMember"  style="color:#409EFF;">Membership: No</span></div>
    <div>
      <cart-item v-for="good in cartGoodList" :key="good.key" :item="good"></cart-item>
    </div>
    <div>
      <div v-show="isMember" style="text-align:right;font-size:16px;padding:8px;">
          Total Price：&nbsp;<span style="color:#F56C6C">{{cartMemberTotal}} <span style="font-size:12px;color:#409EFF;text-decoration:line-through;">{{cartTotal}}</span></span>
      </div>
      <div v-show="!isMember" style="text-align:right;font-size:16px;padding:8px;">
          Total Price：&nbsp;<span style="color:#409EFF">{{cartTotal}}</span>
      </div>
      <div style="text-align:right;font-size:12px;padding:0px 8px 8px 8px;color:#909399;">
          Total Amount：&nbsp;<span>{{cartCount}}</span>
      </div>
      <el-row style="padding:8px;">
          <el-col :span="12"><el-button type="danger" style="width:98%;" @click="clearCartClick">Clear</el-button></el-col>
          <el-col :span="12"><el-button type="primary" style="width:98%;" @click="checkoutClick">Checkout</el-button></el-col>
      </el-row>
      <br/>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import _ from 'lodash'
import CartItem from './CartItem.vue'

export default {
  name: 'cartView',
  components:{
    CartItem
  },
  computed:{
    ...mapState('client', [
        'client',
        'token',
        'tokenTime'
    ]),
    ...mapState('cart', [
        'goodList'
    ]),
    isMember: function() {
        if (this.client === null) {
            return false
        }
        return this.client.is_member
    },
    cartTotal: function(){
       var total = 0 
       if(this.goodList.length == 0) {
           return '$0.00'
       }
       for(var i=0; i < this.goodList.length; i++){
           total += this.goodList[i].sku.price * this.goodList[i].amount
       }
       console.log(this.goodList)
       return '$' + (total/100.0).toFixed(2)
    },
    cartMemberTotal: function(){
       var total = 0 
       if(this.goodList.length == 0) {
           return '$0.00'
       }
       for(var i=0; i < this.goodList.length; i++){
           total += this.goodList[i].sku.member_price * this.goodList[i].amount
       }
       console.log(this.goodList)
       return '$' + (total/100.0).toFixed(2)
    },
    cartCount: function(){
        var count = 0
        for(var i=0; i < this.goodList.length; i++){
            count += this.goodList[i].amount
        }
        return count
    },
    cartGoodList: function(){
        var list = _.cloneDeep(this.goodList)
        return list
    }
  },
  methods: {
    ...mapActions('client',[
        'logout',
        'setView',
        'checkout'
    ]),
    ...mapActions('cart', [
        'clearGoods',
        'resetShipping'
    ]),
    ...mapActions('category', [
        'clearCatgories'
    ]),
    ...mapActions('merchant', [
        'clearMerchants'
    ]),
    ...mapActions('product', [
        'clearProducts'
    ]),
    ...mapActions('order', [
        'clearOrders'
    ]),
    homePageClick() {
        window.location.reload()
        this.setView({
            view: 'index'
        })
    },
    userBtnClick(){
        this.setView({
            view: 'login'
        })
    },
    logoutClick(){
        this.clearGoods()
        this.clearOrders()
        this.logout()
    },
    clearCartClick(){
        this.clearGoods()
    },
    orderListClick(){
        this.setView({
            view: 'clientOrder'
        })
    },
    bookListClick(){
        this.setView({
            view: 'UserBookList'
        })
    },
    personalInfoClick(){
        this.setView({
            view: 'personalInfo'
        })
    },
    adminClick(){
        this.setView({
            view: 'admin'
        })
    },
    checkoutClick(){
        if(this.cartCount == 0){
            return
        }
        if(this.token.length == 0){
            this.$message({
                message: 'Login is required before checkout',
                type: 'warning'
            });
            return
        } else {
            console.log(this.tokenTime)
            var ts = new Date().getTime() - this.tokenTime
            console.log(ts)
            if(ts / 1000 > 3600 * 24 * 3) {
                this.$message({
                    message: 'Your session is expired, need to login again',
                    type: 'warning'
                });
                this.setView({
                    view: 'login'
                })
                return
            }
        }
        this.showDrawer = false;
        this.resetShipping()
        this.checkout()
    }
  }
}
</script>

<style>

</style>