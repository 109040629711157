<template>
  <div id="client">
    <el-card>
      <el-container>
        <el-aside width="200px">
          <h3>Locations</h3>
          <el-divider></el-divider>
          <el-menu default-active="0" style="background-color:#E4E7ED;" @select="locationSelect">
            <el-menu-item index="0">All</el-menu-item>
            <el-menu-item  v-for="location in locations" :key="location.ID" :index="location.ID.toString()">{{location.name}}</el-menu-item>
          </el-menu>
        </el-aside>
        <el-main>
          <div style="text-align:right;"><el-button type="primary" @click="showDrawer=true">Generate Invite Code</el-button></div>
          <el-table :data="clients">
            <el-table-column label="name" prop="name"></el-table-column>
            <el-table-column label="email" prop="email"></el-table-column>
            <el-table-column label="contact no" width="120px" prop="contact_no"></el-table-column>
            <el-table-column label="company" prop="company_name"></el-table-column>
            <el-table-column label="location">
              <template slot-scope="scope">
                {{getLocationName(scope.row.location_id)}}
              </template>
            </el-table-column>
            <el-table-column label="Is Member">
              <template slot-scope="scope">
                <el-switch disabled v-model="scope.row.is_member" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="Action">
              <template slot-scope="scope">
                <el-button type="text" size="mint" @click="resetMemberClick(scope.row)"><span v-show="scope.row.is_member == 0">Set Member</span><span v-show="scope.row.is_member == 1">Remove Member</span></el-button>
              </template>
            </el-table-column>
          </el-table>
          <br/>
          <el-pagination :page-size="pageSize" :current-page="pageIndex" :total="clientCount" @current-change="handlePageChange" background></el-pagination>
        </el-main>
      </el-container>
      <el-drawer title="Generate Invite Code" :visible.sync="showDrawer" dirction="btt" :before-close="handleClose">
        <div style="margin-bottom:10px;text-align:left;margin-left:20px;">Input generate number here</div>
        <el-row>
          <el-col :span="14"><el-input style="margin: 0px 20px;" placeholder="Generate Count" v-model="codeCount" type="number"></el-input></el-col>
          <el-col :span="10"><el-button type="primary" @click="generateClick" :loading="genLoading">Generate</el-button></el-col>
        </el-row>
        <div v-show="codes.length > 0" style="margin-top:20px;text-align:right;margin-left:20px;"><el-button type="danger" size="mini" @click="copyCodes">Copy To Clipboard</el-button></div>
        <ul>
          <li style="text-align:left;margin:3px 0px;list-style:none;" v-for="code in codes" :key="code">{{code}}</li>
        </ul>
      </el-drawer>
    </el-card>
  </div>
</template>

<script>
import userApi from '../api/user'
import {mapState, mapActions} from 'vuex'
export default {
    name: 'client',
    data () {
      return {
        showDrawer: false,
        genLoading: false,
        codeCount: 0,
        codes: []
      }
    },
    computed:{
      ...mapState('util', [
        'locations'
      ]),
      ...mapState('client', [
        'clients',
        'pageSize',
        'pageIndex',
        'clientCount',
        'token'
      ])
    },
    mounted(){
        console.log('client mounted')
        this.getLocations()
        this.getClients(0)
    },
    methods: {
      ...mapActions('util', [
        'getLocations'
      ]),
      ...mapActions('client', [
        'getClients',
        'setPageIndex',
        'resetMember'
      ]),
      handleClose(done) {
        this.$confirm('Confirm Close?')
          .then(_ => {
            console.log(_)
            done();
          })
          .catch(_ => {console.log(_)});
      },
      locationSelect(index){
        console.log(index)
        this.getClients({
          locationId: index
        })
      },
      getLocationName(index){
        var name = ''
        for(var i=0; i < this.locations.length; i++){
          if(this.locations[i].ID === index){
            name = this.locations[i].name 
            break
          }
        }
        return name
      },
      handlePageChange(val){
        this.setPageIndex({
          pageIndex: val
        })
      },
      generateClick(){
        if (this.codeCount === 0) {
          return
        }
        this.genLoading = true
        userApi.generateCode(this.codeCount, this.token).then(resp=>{
          console.log(resp)
          this.codes = resp
          this.genLoading = false
        })
      },
      copyCodes(){
        if(this.codes.length == 0){
          return
        }
        var message = ''
        this.codes.forEach(c => {
          message += c + '\r\n'
        })
        var self = this
        this.$copyText(message).then(function(e){
          self.$message({
            message: 'Copied',
            type: 'success'
          })
          console.log(e)
        }, function(e){
          console.log(e)
          self.$message({
            message: 'Your system cannot support copy, please copy by manual',
            type: 'warning'
          })
        })
      },
      resetMemberClick(row){
        this.resetMember({
          client: row
        })
      }
    }
}
</script>

<style>

</style>