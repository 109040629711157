<template>
  <div id="order">
      <el-card>
          <div slot="header" style="text-align:left;">
              <el-form :inline="true">
                <el-form-item label="By">
                    <el-select v-model="searchBy" placeholder="">
                      <el-option v-for="(item, index) in searchByItems" :key="index" :label="item" :value="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="=">
                    <el-input v-model="searchByValue" placeholder="" clearable></el-input>
                </el-form-item>
                <el-form-item label="Location">
                    <el-select v-model="locationId" placeholder="choose location" clearable>
                      <el-option v-for="item in locations" :key="item.ID" :label="item.name" :value="item.ID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Date">
                    <el-date-picker value-format="yyyy-MM-dd" v-model="selectDate" placeholder="" type="date" clearable></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="search">Search</el-button>
                </el-form-item>
              </el-form>
              <div>
                  <el-button type="danger" @click="resendDailyEmail">Resend Daily Email</el-button>
              </div>
          </div>
          <el-container style="display:block;">
              <order-item v-for="item in adminOrderList" :key="item.ID" :item="item"></order-item>
              <el-pagination :page-size="pageSize" :current-page="pageIndex" :total="adminOrderCount" @current-change="handlePageChange" background></el-pagination>
          </el-container>
      </el-card>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import OrderItem from './OrderItem.vue'
import axios from 'axios'
import orderApi from '../api/order'

export default {
    name: 'order',
    components: {
        OrderItem
    },
    data(){
        return {
            searchByItems: [
              'company name',
              'client name',
              'client email'
            ],
            searchBy: 0,
            locationId: '',
            name: '',
            email: '',
            searchByValue: '',
            selectDate: '',
            locations: []
        }
    },
    computed: {
        ...mapState('client', ['token']),
        ...mapState('order', [
            'pageSize',
            'pageIndex',
            'adminOrderCount',
            'adminOrderList'
        ]),
        searchByName: function () {
            if(this.searchBy === 0) {
                return 'companyName'
            } else if(this.searchBy === 1) {
                return 'clientName'
            } else {
                return 'clientEmail'
            }
        }
    },
    mounted(){
        console.log('order mounted')
        this.loadLocations()
    },
    methods: {
        ...mapActions('order', [
            'getAllOrderList',
            'setAllOrderCurrentIndex'
        ]),
        search(){
            console.log(this.locationId, this.searchByName, this.searchByValue, this.selectDate)
            this.getAllOrderList({
                locationId: this.locationId,
                searchByName: this.searchByName,
                searchByValue: this.searchByValue,
                date: this.selectDate
            })
        },
        loadLocations(){
            var self = this 
            axios.get('/api/location/list').then(resp=>{
                console.log(resp)
                if(resp.status == 200 && resp.data.code == 200){
                    self.locations = resp.data.result
                }
            })
        },
        handlePageChange(val){
            this.setAllOrderCurrentIndex({
                pageIndex: val,
                locationId: this.locationId,
                searchByName: this.searchByName,
                searchByValue: this.searchByValue,
                date: this.selectDate
            })
        },
        resendDailyEmail(){
            orderApi.sendDailyEmail(this.token).then(()=>{
                this.$message({
                    type: 'success',
                    message: 'start to resend daily email'
                })
            })
        }
    }
}
</script>

<style>

</style>