<template>
  <div id="cartItem" style="display:-webkit-box;margin:8px;padding:8px 0px;border-bottom:1px solid #E4E7ED;">
    <el-image :src="item.product.image" fit="fit" style="width:60px;height:60px"></el-image>
    <div style="-webkit-box-flex:1;margin-left:8px; text-align:left;">
        <span style="font-size:14px;line-height:14px;height:28px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;">{{item.product.name}}</span>
        <div style="margin-top:8px;display:-webkit-box;">
            <span v-show="isMember" style="font-size:14px;color:#F56C6C;display:block;-webkit-box-flex:1;line-height:30px;">${{(item.sku.member_price/100.0).toFixed(2)}} for {{item.sku.name}}</span>
            <span v-show="!isMember" style="font-size:14px;color:#409EFF;display:block;-webkit-box-flex:1;line-height:30px;">${{(item.sku.price/100.0).toFixed(2)}} for {{item.sku.name}}</span>
            <el-input-number size="mini" :min="1" :max="999" :step="1" v-model="item.amount" style="width:110px;" @change="amountChangeClick"></el-input-number>
        </div>
    </div>
    <div style="padding:0px 8px;">
        <el-button type="text" icon="el-icon-delete" style="color:#F56C6C;" @click="removeGoodClick"></el-button>
     </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
    name: 'cartitem',
    props: ['item'],
    computed: {
        ...mapState('client', ['client']),
        isMember: function(){
            if (this.client === null) {
                return false
            }
            return this.client.is_member
        }
    },
    methods: {
        ...mapActions('cart', [
            'changeGoodAmount',
            'removeGood'
        ]),
        amountChangeClick(val, oldval){
            console.log(val, oldval)
            this.changeGoodAmount({
                key: this.item.key,
                amount: val
            })
        },
        removeGoodClick(){
            this.removeGood({
                key: this.item.key
            })
        }
    }
}
</script>

<style>

</style>