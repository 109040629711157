<template>
  <el-card id="hotelRoom"> 
      <div slot="header" style="text-align:left;font-weight:bold;">Room
          <el-button style="float:right;" size="small" type="warning" @click="removeRoomClick">Remove Room</el-button>
      </div>
      <el-form label-width="120px" label-position="left">
          <el-form-item label="Name">
              <el-input v-model="item.name"></el-input>
          </el-form-item>
          <el-form-item label="Description">
              <el-input v-model="item.text"></el-input>
          </el-form-item>
          <el-form-item label="Images" style="text-align:left;">
                <div v-for="(image, index) in item.images" :key="index" style="width:200px;">
                    <div style="text-align:right;position:relative;top:20px;left:10px;z-index:99;">
                    <el-button style="width:25px;height:25px;padding:5px;" type="danger" icon="el-icon-close" circle @click="removeImage(image)"></el-button>
                </div>
                <el-image style="width:200px;height:200px;" :src="image.image"></el-image>
                </div>
                <el-upload action="" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="fileUpload">
                    <el-button size="small" type="primary" :loading="fileLoading">Upload</el-button>
                    <div slot="tip" class="el-upload__tip">jpg/png file only，file size below 2MB</div>
                </el-upload>
          </el-form-item>
          <el-form-item label="Room Type" style="text-align:left;">
              <el-button size="small" type="danger" @click="addRoomType">Add Type</el-button>
              <hotel-room-type v-for="(roomType, index) in item.room_type_prices" :key="index" :item="roomType" @removeRoomType="removeRoomTypeClick"></hotel-room-type>
          </el-form-item>
      </el-form>
  </el-card>
</template>

<script>
import aliossApi from '../../api/alioss'
import { mapState } from 'vuex'
import HotelRoomType from './HotelRoomType.vue'

export default {
  name: 'hotelRoom',
  props: ['item'],
  data () {
      return {
          fileLoading: false,
      }
  },
  components: {
      HotelRoomType
  },
  computed: {
      ...mapState('client', ['token'])
  },
  methods: {
    beforeAvatarUpload(file) {
         console.log(file)
         const isJPG = file.type === 'image/jpeg';
         const isPNG = file.type === 'image/png';
         const isLt2M = file.size / 1024 / 1024 < 2;

         if (!isJPG && !isPNG) {
           this.$message.error('JPG/PNG only!');
         }
         if (!isLt2M) {
           this.$message.error('file size must less than 2MB!');
         }
         return (isJPG || isPNG) && isLt2M;
    },
    fileUpload(options){
        this.fileLoading = true 
        console.log(options)
        aliossApi.uploadFile(options.file, this.token).then(imageUrl=>{
            this.fileLoading = false
            this.item.images.push({
                ID: 0, 
                room_id: this.item.ID, 
                image: imageUrl})
        }).catch(()=>{
            this.fileLoading = false
        })
    },
    addRoomType(){
        this.item.room_type_prices.push({
            ts: new Date().getTime(),
            room_id:0,
            room_type: '',
            text: '',
            week_day_price: '',
            week_end_price: ''
        })
    },
    removeRoomTypeClick(roomType){
        var index = -1
        for(var i = 0; i < this.item.room_type_prices.length; i++){
            if(this.item.room_type_prices[i].ts === roomType.ts){
                index = i
                break
            }
        }
        if(index > -1){
            this.item.room_type_prices.splice(index, 1)
        }
    },
    removeRoomClick(){
        this.$emit("removeRoom", this.item)
    },
    removeImage(image){
        var index = -1
        for(var i=0; i<this.item.images.length; i++){
            if(this.item.images[i].ts === image.ts) {
                index = i
                break
            }
        }
        if(index > -1){
            this.item.images.splice(index, 1)
        }
    }
  }
}
</script>

<style>

</style>