<template>
  <el-card id="hotelRoomType" shadow="never" style="margin:10px 0;">
      <div slot="header" style="padding:0;font-weight:bold;">Room Type
          <el-button style="float:right;" size="small" type="warning" @click="removeClick">Remove Type</el-button>
      </div>
      <el-form :model="item" label-width="140px" label-position="left" :inline="true">
          <el-form-item label="Type Name" style="padding-bottom:20px;">
              <el-input v-model="item.room_type"></el-input>
          </el-form-item>
          <el-form-item label="Description" style="padding-bottom:20px;">
              <el-input v-model="item.text"></el-input>
          </el-form-item>
          <el-form-item label="Week Day Price($)" style="padding-bottom:20px;">
              <el-input v-model="item.week_day_price"></el-input>
          </el-form-item>
          <el-form-item label="Week End Price($)">
              <el-input v-model="item.week_end_price"></el-input>
          </el-form-item>
      </el-form>
  </el-card>
</template>

<script>
export default {
    name: 'hotelRoomType',
    props: ['item'],
    methods: {
        removeClick(){
            this.$emit('removeRoomType', this.item)
        }
    }
}
</script>

<style>

</style>