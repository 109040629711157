<template>
  <div id="payment" style="text-align:left;">
        <div style="font-size:18px;padding:20px 20px 8px 20px;border-bottom:1px solid #E4E7ED">Payment</div>
        <div style="padding:20px;background-color:#E4E7ED;margin-top:8px;">
           <div style="font-size:16px;">Payment</div>
           <div class="address-item" style="display:-webkit-box;">
               <el-radio style="display:block;line-height:40px;margin:0px 0px;"></el-radio><el-image @click="startPaymentClick" :src="require('../assets/paynow.png')" style="width:80px;"></el-image>
            </div>
       </div>
       <el-row>
           <el-col :span="24" :xs="0">
               <el-dialog :visible.sync="dialog" title="Pay Action" :before-close="handleClose">
           <div style="padding:20px;background-color:#E4E7ED;margin-top:8px;">
                <div style="font-size:16px;text-align:center;font-weight:bold;">Scan To Pay</div>
                <div style="text-align:center;padding:20px;">
                    <vue-qr :text="qrcode" :logoSrc="require('../assets/paynow.png')" :logoScale="0.2" :size="223" colorDark="#7D1979"></vue-qr>
                </div>
                <div style="color:red;text-align:center;"><i class="el-icon-loading"></i><span style="margin-left:5px;">Payment Pending...Please do not close or leave this webpage before your payment is confirmed.</span></div>
            </div>
            <div slot="footer">
                <el-button type="danger" @click="cancelPaymentClick">Cancel Payment</el-button>
            </div>
       </el-dialog>
           </el-col>
           <el-col :span="24" :xs="24" :sm="0" :md="0" :lg="0" :xl="0">
               <el-dialog :visible.sync="dialog" width="100%" title="Pay Action" :before-close="handleClose">
                    <div style="padding:20px;background-color:#E4E7ED;margin-top:8px;">
                         <div style="font-size:16px;text-align:center;font-weight:bold;">Save QR To Pay</div>
                         <div style="text-align:center;padding:20px;">
                             <vue-qr :text="qrcode" :logoSrc="require('../assets/paynow.png')" :logoScale="0.2" :size="223" colorDark="#7D1979"></vue-qr>
                         </div>
                         <div style="color:red;text-align:center;"><i class="el-icon-loading"></i><span style="margin-left:5px;">Payment Pending...Please do not close or leave this webpage before your payment is confirmed.</span></div>
                     </div>
                     <div slot="footer">
                         <el-button type="danger" @click="cancelPaymentClick">Cancel Payment</el-button>
                     </div>
                </el-dialog>
           </el-col>
       </el-row>
       
  </div>
</template>

<script>
import VueQr from 'vue-qr'

export default {
    name: 'payment',
    components: {
        VueQr
    },
    props: ['qrcode'],
    data(){
        return {
            payBy: 'direct',
            dialog: false,
            active: 0
        }
    },
    watch: {
        qrcode: function(val, oldVal) {
            console.log(val, oldVal)
            this.dialog = val !== ''
        }
    },
    methods: {
        cancelPaymentClick(){
            this.$confirm('Confirm Cancel Payment?').then(_=>{
                console.log(_)
                this.$emit('cancelPayment')
            }).catch(_=>{console.log(_)})
        },
        startPaymentClick(){
            this.$emit('startPayment')
        },
        handleClose(done){
            this.$confirm('Confirm Cancel Payment?').then(_=>{
                console.log(_)
                done()
                this.$emit('cancelPayment')
            }).catch(_=>{console.log(_)})
        }
    }
}
</script>

<style>
.dialog-small {
    width: 100% !important;
}
</style>