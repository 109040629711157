<template>
  <div id="checkout" style="max-width:1024px; margin:0px auto; background-color:#fff;padding:0px; border:1px solid #DCDFE6">
    <el-container style="padding:0;">
      <el-header>
        <div  style="margin-top:20px; padding:0 10px;background-color:#E4E7ED; height:48px; border-radius:5px; line-height:48px; text-align:left; font-size:18px;">
          <el-link type="primary" :underline="false" style="font-size:18px;" @click="backToHome">Home</el-link>
          <el-link type="info" :underline="false" style="margin: 0px 5px; font-size:18px;">/</el-link>
          <el-link type="info" :underline="false" style="font-size:18px;">Preview Order</el-link>
        </div>
      </el-header>
      <el-main style="padding:0px;">
        <div style="font-size:18px;text-align:left;padding:20px 20px 8px 20px;border-bottom:1px solid #DCDFE6">Order Content</div>
        <div style="margin-top:8px;">
          <template v-for="(item, index) in goodListByMerchants">
            <el-card :key="index" style="margin-top:5px;" shadow="never">
              <div slot="header" style="display:-webkit-box;">
                <div style="-webkit-box-flex:1;text-align:left;font-weight:bold;">{{item.merchant.name}}</div>
                <div style="text-align:right;font-size:14px;">
                  <span v-if="item.merchant.ID > 0">
                    <span style="font-weight:bold;">${{(item.deliver.free_fee / 100.0).toFixed(2)}}</span> 
                    to <span style="color:red;font-weight:bold;">FREE</span> delivery
                  </span>
                  <span v-else></span>
                  <div style="font-size:12px;color:grey;">(deliver from {{item.deliver.deliver_service}})</div>
                </div>
              </div>
              <el-row>
                <el-col :span="24" :xs="0">
                  <checkout-item v-for="good in item.goods" :key="good.key" :item="good" view="large"></checkout-item>
                </el-col>
                <el-col :span="24" :xs="24" :sm="0" :md="0" :lg="0" :xl="0">
                  <checkout-item v-for="good in item.goods" :key="good.key" :item="good" view="small"></checkout-item>
                </el-col>
              </el-row>
              <el-divider></el-divider>
              <div style="font-size:12px;color:grey;text-align:right;margin-right:10px;">
                Goods Fee: <span style="display:inline-block;;width:60px;"><span v-show="!isMember">${{(item.goods_fee / 100.0).toFixed(2)}}</span><span v-show="isMember">${{(item.goods_member_fee / 100.0).toFixed(2)}}</span></span>
              </div>
              <div style="font-size:12px;color:grey;text-align:right;margin-right:10px;">
                Delivery Fee: <span style="display:inline-block;;width:60px;"><span v-show="!isMember">${{(item.delivery_fee / 100.0).toFixed(2)}}</span><span v-show="isMember">${{(item.delivery_member_fee / 100.0).toFixed(2)}}</span></span>
              </div>
              <div style="font-size:12px;color:grey;text-align:right;margin-right:10px;">
                Member Discount: <span style="display:inline-block;;width:60px;color:red;"><span v-show="!isMember">-${{(item.delivery_fee / 100.0).toFixed(2)}}</span><span v-show="isMember">-${{(item.delivery_member_fee / 100.0).toFixed(2)}}</span></span>
              </div>
              <div style="text-align:right;margin-right:10px;margin-top:10px;">Sub Total: <span style="display:inline-block;;width:60px;"><span v-show="!isMember">${{(item.sub_total / 100.0).toFixed(2)}}</span><span v-show="isMember">${{(item.sub_member_total / 100.0).toFixed(2)}}</span></span></div>
            </el-card>
          </template>
        </div>
        <div style="font-size:18px;text-align:right;padding:20px 20px 8px 20px;border-bottom:1px solid #DCDFE6">Order Total</div>
        <div style="color:#F56C6C;text-align:right;padding:20px 20px 0px 20px;font-size:18px;"><span v-show="!isMember">${{cartTotal}}</span><span v-show="isMember">${{cartMemberTotal}}</span></div>
        <el-row>
          <el-col :span="12" :xs="24">
            <shipping style="margin-right:10px;margin-left:10px;"></shipping>
          </el-col>
          <el-col :span="12" :xs="24">
            <payment style="margin-left:10px;margin-right:10px;" :qrcode="qrcode" v-on:startPayment="placeOrder" @cancelPayment="cancelPay"></payment>
          </el-col>
        </el-row>
        <div style="text-align:right;margin-top:20px;margin-right:10px;margin-bottom:20px;">
          <el-button type="danger" style="max-width:200px;" @click="placeOrder" :loading="loading" v-show="showPayBtn">{{loading ? "Payment Pending" : "Start Payment"}}</el-button>
          <el-button type="primary" style="max-width:200px;" @click="cancelPay">Cancel Payment</el-button>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import Shipping from './Shipping.vue'
import Payment from './Payment.vue'
import CheckoutItem from './CheckoutItem.vue'
export default {
    name: 'checkout',
    components:{
      Shipping,
      Payment,
      CheckoutItem
    },
    data(){
      return {
        loading: false,
        qrcode: '',
        timer: null,
        orderId: 0,
        showPayBtn: true
      }
    },
    computed:{
      ...mapState('cart', [
        'goodList',
        'shipping'
      ]),
      ...mapGetters('cart', [
        'goodListByMerchants',
        'cartTotal',
        'cartTotalInFen',
        'cartMemberTotal',
        'cartMemberTotalInFen'
      ]),
      ...mapState('client', [
        'client',
        'token',
        'tokenTime'
      ]),
      isMember: function(){
        return this.client.is_member
      }
    },
    watch: {
      shipping: {
        handler(newVal, oldVal) {
          console.log(newVal, oldVal)
          if(newVal.expectedShippingDate.length === 0) {
            this.orderId = 0
          }
        },
        deep: true,
        immediate: true,
      }
    },
    mounted(){
      var paid = this.$route.query.paid
      console.log(paid)
      if(paid) {
        var status = this.$route.query.status
        if(status === 'completed'){
          this.clearGoods()
          this.$alert('Order Paid Successfully, click to back to home page', 'Order Paid', {
            confirmButtonText: 'Goto Home Page',
            callback: ()=>{
              this.setView({
                view: 'index'
              })
              window.location.href = "https://ucommune.shop"
            }
          })          
        }
      }
    },
    methods: {
      ...mapActions('client', [
        'setView',
        'relogin',
      ]),
      ...mapActions('cart', [
        'checkout',
        'clearGoods'
      ]),
      ...mapActions('order', [
        'getOrderStatus'
      ]),
      placeOrder(){
        if(!this.checkShipping()){
          return
        }
        var order = this.createOrder()
        console.log(order)
      },
      backToHome(){
        window.location.reload()
        this.setView({view:'index'})
      },
      checkShipping(){
        console.log(this.shipping)
        if(this.shipping.expectedShippingDate.length == 0){
          this.$message({
            message: 'expected shipping date is required',
            type: 'error'
          })
          return false
        }
        /*if(this.shipping.expectedShippingTime.length == 0){
          this.$message({
            message: 'expected shipping time is required',
            type: 'error'
          })
          return false
        }*/
        if(this.shipping.location == null){
          this.$message({
            message: 'shipping location is required',
            type: 'error'
          })
          return false
        }
        return true
      },
      createOrder(){
        if(this.goodList.length == 0){
          return;
        }
        this.loading = true 
        this.checkout().then(resp=>{
          this.qrcode = resp.qr_code
          this.orderId = resp.order_id
          this.showPayBtn = false
          //window.open(resp, '_self')
          this.timer = setInterval(this.checkStatusLoop, 5000)
        }).catch(err=>{
          if(err.response){
            console.log(err.response.data)
            console.log(err.response.status)
            if(err.response.status == 401){
               this.$message({
                    message: 'Login is out of date, please login again',
                    type: 'warning'
                });
              this.relogin()
            }
          }
        })
      },
      checkStatusLoop() {
        if(this.orderId === 0 && this.timer !== null) {
          clearInterval(this.timer)
          this.orderId = 0
          this.timer = null
          return
        }
        this.getOrderStatus({
          orderId: this.orderId 
        }).then(resp=>{
          if(resp == 'paid'){
            this.clearGoods()
            clearInterval(this.timer)
            this.timer = null
            this.$alert('Order Paid Successfully, click to back to home page', 'Order Paid', {
              confirmButtonText: 'Goto Home Page',
              callback: ()=>{
                this.setView({
                  view: 'index'
                })
                window.location.href = "https://ucommune.shop"
              }
            })          
          }
        })
      },
      cancelPay(){
        this.loading = false
        this.qrcode = ''
        if(this.timer !== null){
          clearInterval(this.timer)
          this.timer = null
          this.orderId = 0
        }
        this.setView({
          view: 'index'
        })
      }
    }
}
</script>

<style>

</style>