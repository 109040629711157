<template>
    <el-main v-loading="fullLoading" id="hotelView" style="max-width:1024px; margin: 0px auto; background-color:#fff;padding:0px; border:1px solid #DCDFE6">
        <el-row>
            <el-col :span="24">
                <div style="margin:20px;padding: 0px 20px;background-color:#E4E7ED;border-radius:5px; line-height:48px; text-align:left; font-size:18px;">
                <el-link type="primary" :underline="false" style="font-size:18px;" @click="gotoIndex">Home</el-link>
                <el-link type="info" :underline="false" style="margin: 0px 5px; font-size:18px;">/</el-link>
                <el-link type="primary" :underline="false"  style="font-size:18px;" @click="showDetail=false">Hotel</el-link>
                <el-link type="info" :underline="false" v-show="showDetail || showBooking" style="margin: 0px 5px; font-size:18px;">/</el-link>
                <el-link type="info" :underline="false" v-show="showDetail || showBooking" style="font-size:18px;">{{hotel.name}}</el-link>
                </div>
            </el-col>
        </el-row>
        <el-row v-show="!showDetail && !showBooking" style="padding:0px;margin:0px;">
            <el-col :span="24" :xs="0">
                <el-card style="margin:0px 20px 10px 20px;text-align:left;border-bottom:1px solid #DCDFE6;" shadow="hover" :body-style="{padding: '5px', margin: '0px'}" v-for="hotel in hotels" :key="hotel.ID">
                    <el-row>
                        <el-col :span="24">
                            <el-row style="padding:10px;">
                                <el-col :span="3">
                                    <el-image style="width:120px;height:100px;" :src="hotel.images[0].image"></el-image>
                                </el-col>
                                <el-col :span="17" style="padding-left:10px;">
                                    <div style="font-weight:bold;">{{hotel.name}}</div>
                                    <div style="font-size:12px;padding:5px 0px;">{{hotel.contact_number}}</div>
                                    <div style="font-size:12px;">{{hotel.address}}</div>
                                    <div style="font-size:12px;padding:5px 0px;">Validity: {{hotel.validity}}</div>
                                </el-col>
                                <el-col :span="4" style="border-left:1px solid #E4E7ED;height:100px;text-align:right;">
                                    <div v-if="hotel.rooms.length > 0" style="color:#409EFF;font-size:18px;font-weight:bold;margin-top:30px;">S${{hotel.rooms[0].room_type_prices[0].week_day_price}}</div>
                                    <div style="margin-top:10px;">
                                        <el-button type="primary" @click="showDetailClick(hotel)">Show Detail</el-button>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="24" :sm="0" :md="0" :lg="0" :xt="0">
                <el-row v-for="hotel in hotels" :key="hotel.ID" style="margin-bottom:10px;border-bottom:1px solid #DCDFE6;">
                    <el-col :span="24">
                        <div style="text-align:left;font-weight:bold;margin-left:10px;">{{hotel.name}}</div>
                    </el-col>
                    <el-col :span="24" style="padding:5px 10px;margin-bottom:5px">
                        <div style="display:-webkit-box;">
                            <div>
                                <el-image style="width:120px;height:100px;" :src="hotel.images[0].image"></el-image>
                            </div>
                            <div style="margin-left:10px;text-align:left;-webkit-box-flex:1;">
                                <div style="font-size:12px;padding:5px 0px;">{{hotel.contact_number}}</div>
                                <div style="font-size:12px;">{{hotel.address}}</div>
                                <div style="font-size:12px;padding:5px 0px;">Validity: {{hotel.validity}}</div>
                                <div style="display:-webkit-box;">
                                    <div v-if="hotel.rooms.length > 0" style="color:#409EFF;font-size:18px;font-weight:bold;line-height:40px;"><!--S${{hotel.rooms[0].room_type_prices[0].week_day_price}}--></div>
                                    <div style="-webkit-box-flex:1;text-align:right;"><el-button type="text" @click="showDetailClick(hotel)">Show Detail</el-button></div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row v-show="showDetail">
            <el-col :span="24">
                <el-card style="margin:0px 10px 10px 10px;text-align:left;" shadow="hover" :body-style="{padding: '10px', margin: '0px'}">
                    <div slot="header" style="text-align:right;">
                        <el-button type="text" icon="el-icon-arrow-left" @click="showDetail=false">Back To List</el-button>
                    </div>
                    <el-row>
                        <el-col :span="24">
                            <div style="font-weight:bold;">{{hotel.name}}</div>
                            <div style="font-size:14px;padding:5px 0px;"><i class="el-icon-location"></i>{{hotel.address}}</div>
                            <div style="font-size:12px;"><i class="el-icon-phone"></i>{{hotel.contact_number}}</div>
                            <el-carousel height="250px" style="text-align:center;padding:5px 0px;">
                                <el-carousel-item v-for="image in hotel.images" :key="image.ID">
                                    <el-image style="height:250px;" :src="image.image" :fit="fit"></el-image>
                                </el-carousel-item>
                            </el-carousel>
                            <el-collapse>
                                <el-collapse-item>
                                    <template slot="title"><div style="font-weight:bold;">Privileges</div></template>
                                    <ul>
                                        <li v-for="p in hotel.privileges" :key="p.ID">{{p.text}}</li>
                                    </ul>
                                </el-collapse-item>
                                <el-collapse-item>
                                    <template slot="title"><div style="font-weight:bold;">Conditions</div></template>
                                    <ul>
                                        <li v-for="p in hotel.conditions" :key="p.ID">{{p.text}}</li>
                                    </ul>
                                </el-collapse-item>
                                <el-collapse-item>
                                    <template slot="title"><div style="font-weight:bold;">Inclusions</div></template>
                                    <ul>
                                        <li v-for="p in hotel.inclusions" :key="p.ID">{{p.text}}</li>
                                    </ul>
                                </el-collapse-item>
                                <el-collapse-item>
                                    <template slot="title"><div style="font-weight:bold;">Remarks</div></template>
                                    <ul>
                                        <li v-for="p in hotel.remarks" :key="p.ID">{{p.text}}</li>
                                    </ul>
                                </el-collapse-item>
                                <el-collapse-item>
                                    <template slot="title"><div style="font-weight:bold;">Cancel Policies</div></template>
                                    <ul>
                                        <li v-for="p in hotel.cancel_policies" :key="p.ID">{{p.text}}</li>
                                    </ul>
                                </el-collapse-item>
                                <el-collapse-item>
                                    <template slot="title"><div style="font-weight:bold;">Blackout Dates</div></template>
                                    <ul>
                                        <li v-for="p in hotel.blackout_dates" :key="p.ID">{{p.text}}</li>
                                    </ul>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="font-weight:bold;margin:10px 0px;padding-bottom:10px;border-bottom:1px solid #DCDFE6;">Rooms</div>
                            <el-row v-for="room in hotel.rooms" :key="room.ID">
                                <el-col :span="3" :xs="0" style="text-align:left;">
                                    <el-image style="width:120px;height:100px;" :src="room.images[0].image"></el-image>
                                </el-col>
                                <el-col :span="6" :xs="0" style="text-align:left;padding-left:10px;">
                                    <div style="font-weight:bold;">{{room.name}}</div>
                                    <div>{{room.text}}</div>
                                </el-col>
                                <el-col :xs="0" :span="11" style="text-align:right;">
                                    <el-row style="font-size:14px;font-weight:bold;">
                                        <el-col :span="8">Room Type</el-col>
                                        <el-col :span="8">Weekday</el-col>
                                        <el-col :span="8">Weekend</el-col>
                                    </el-row>
                                    <el-row v-for="price in room.room_type_prices" :key="price.ID" style="margin-top:10px;">
                                        <el-col :span="8">{{price.room_type}}</el-col>
                                        <el-col :span="8" style="color:#409EFF;">{{price.week_day_price}}</el-col>
                                        <el-col :span="8" style="color:#409EFF;">{{price.week_end_price}}</el-col>
                                    </el-row>
                                </el-col>
                                <el-col :span="4" :xs="0">
                                    <div style="margin-top:30px;text-align:right;">
                                        <el-button type="primary" @click="bookingClick(room)">Booking</el-button>
                                    </div>
                                </el-col>
                                <el-col :span="24" :xs="24" :sm="0" :md="0" :lg="0" :xt="0" style="margin-bottom:10px;padding-bottom:10px;border-bottom:1px solid #DCDFE6;">
                                    <div style="display:-webkit-box;margin-top:5px;">
                                        <div :span="12"><el-image style="width:100px;height:80px;" :src="room.images[0].image"></el-image></div>
                                        <div :span="12" style="padding-left:5px;font-size:14px;-webkit-box-flex:1;">
                                            <div style="font-size:14px;font-weight:600;">{{room.name}}</div>
                                            <div v-for="price in room.room_type_prices" :key="price.ID" style="font-size:14px;">
                                                <div>{{price.room_type}}</div>
                                                <div style="display:-webkit-box;">
                                                    <div style="-webkit-box-flex:1;">weekday <span style="color:#409EFF;">${{price.week_day_price}}</span></div>
                                                    <div style="-webkit-box-flex:1;">weekend <span style="color:#409EFF;">${{price.week_end_price}}</span></div>
                                                </div>
                                            </div>
                                            <div style="margin-top:0px;text-align:right;margin-top:5px;">
                                            <el-button type="primary" size="mini" @click="bookingClick(room)">Booking</el-button>
                                        </div>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <el-row v-show="showBooking">
            <el-col :span="24">
                <el-card>
                    <div slot="header" style="font-weight:bold;">
                        <el-button type="text" style="float:right;margin-top:-10px;" icon="el-icon-arrow-left" @click="backToHotel">Back To Hotel</el-button>
                    </div>
                    <div style="font-weight:bold;text-align:left;padding:10px 0px;">Hotel: {{hotel.name}}</div>
                    <div style="font-weight:bold;text-align:left;padding:10px 0px;">Room:</div>
                    <el-row>
                        <el-col :span="3" :xs="8" style="text-align:left;">
                            <el-image style="width:120px;height:100px;" :src="selectRoomImage"></el-image>
                        </el-col>
                        <el-col :span="9" :xs="16" style="text-align:left;padding-left:20px;">
                            <div style="font-weight:bold;">{{selectRoom.name}}</div>
                            <div style="margin-top:10px;">
                                <el-select v-model="selectRoomTypePrice" placeholder="select room type">
                                    <el-option v-for="price in selectRoom.room_type_prices" :key="price.ID" :label="price.room_type" :value="price"></el-option>
                                </el-select>
                                <div v-show="selectRoomTypeWeekDayPrice.length > 0" style="font-size:12px;margin-top:5px;">
                                    <span style="font-weight:bold;">Weekday Price:</span><span  style="color:#409EFF;margin-left:10px;">{{selectRoomTypeWeekDayPrice}}</span>
                                </div>
                                <div v-show="selectRoomTypeWeekEndPrice.length > 0" style="font-size:12px;">
                                    <span style="font-weight:bold;">Weekend Price:</span><span  style="color:#409EFF;margin-left:10px;">{{selectRoomTypeWeekEndPrice}}</span>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:10px;">
                        <el-col :span="3" :xs="24" style="font-weight:bold;line-height:40px;text-align:left;">Booking Date:</el-col>
                        <el-col :span="20" :xs="24" style="text-align:left;">
                            <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="selectRoomDateRange" range-separator="to" start-placeholder="Checkin Date" end-placeholder="Checkout Date"></el-date-picker>
                        </el-col>
                    </el-row>
                    <div style="margin-top:20px; text-align:left;"><el-button type="primary" @click="bookingRoom" :disabled="!isMember"><span v-show="isMember">Booking Now</span><span v-show="!isMember">Only Member Can Book</span></el-button></div>
                </el-card>
            </el-col>
        </el-row>
    </el-main>
</template>

<script>
import hotelApi from '../api/hotel.js'
import {mapState, mapActions} from 'vuex'

export default {
    name: 'hotelView',
    data(){
        return {
            fullLoading: false,
            selectRoomTypePrice: null,
            selectRoomDateRange: '',
            fit: 'contain',
            showDetail: false,
            showBooking: false,
            selectRoom: {
                name: '',
                images: [],
                room_type_prices: []
            },
            hotel: {
                name: '',
                address: '',
                contact_number: '',
                images: [],
                privileges: [],
                conditons: [],
                remarks: [],
                cancel_policies: [],
                blackout_dates: [],
                rooms:[]
            },
            hotels: []
        }
    },
    computed: {
        ...mapState('client', [
            'token', 
            'client'
        ]),
        isMember: function(){
            if(this.client === null) {
                return false
            }
            return this.client.is_member
        },
        selectRoomImage: function(){
            if(this.selectRoom.images.length == 0){
                return ''
            }
            return this.selectRoom.images[0].image
        },
        selectRoomTypeWeekDayPrice: function(){
            if(this.selectRoomTypePrice === null) {
                return ''
            }
            return this.selectRoomTypePrice.week_day_price
        },
        selectRoomTypeWeekEndPrice: function(){
            if(this.selectRoomTypePrice === null) {
                return ''
            }
            return this.selectRoomTypePrice.week_end_price
        }
    },
    mounted(){
        this.getHotelList()
    },
    methods: {
        ...mapActions('client', [
            'setView'
        ]),
        gotoIndex(){
            window.location.reload()
            this.setView({
                view: 'Index'
            })
        },
        getHotelList(){
            this.fullLoading = true 
            hotelApi.list().then(resp=>{
                this.hotels = resp
                this.fullLoading = false
            })
        },
        showDetailClick(hotel){
            this.hotel = hotel
            this.showDetail = true
        },
        bookingClick(room){
            this.selectRoom = room
            this.showBooking = true
            this.showDetail = false
        },
        backToHotel(){
            this.showBooking = false
            this.showDetail = true
        },
        bookingRoom(){
            console.log(this.hotel, this.selectRoom, this.selectRoomTypePrice, this.selectRoomDateRange)
            if (this.selectRoomDateRange.length != 2) {
                return
            }
            if (this.selectRoomTypePrice == null) {
                return
            }
            var data = {
                ID: 0,
                hotel_id : this.hotel.ID,
                hotel_name: this.hotel.name,
                hotel_contact_number: this.hotel.contact_number,
                hotel_contact_email: this.hotel.email,
                hotel_address: this.hotel.address,
                merchant_id: this.hotel.merchant_id,
                merchant_name: this.hotel.merchant.name,
                merchant_email: this.hotel.merchant.email,
                room_id: this.selectRoom.ID,
                room_name: this.selectRoom.name,
                room_type_price_id: this.selectRoomTypePrice.ID,
                room_type_price_name: this.selectRoomTypePrice.room_type,
                room_type_week_day_price: this.selectRoomTypePrice.week_day_price,
                room_type_week_end_price: this.selectRoomTypePrice.week_end_price,
                date_from: this.selectRoomDateRange[0],
                date_to: this.selectRoomDateRange[1]
            }
            console.log(data)
            hotelApi.book(data, this.token).then(resp=>{
                console.log(resp)
                this.$message({
                    message: 'book email send, please contact hotel directly to make sure booking is successfull',
                    type: 'success'
                });
                this.backToHotel()
            })
        }
    }
}
</script>

<style>
</style>