<template>
  <div id="admin" style="max-width:1280px; margin: 10px auto;background-color:#fff;padding:10px;">
      <el-card>
          <el-radio-group v-model="currentTab">
            <el-radio-button v-for="tab in tabs" :key="tab" :label="tab"></el-radio-button>
          </el-radio-group>
      </el-card>
      <component :is="currentComponent" style="margin-top:20px;"></component>
  </div>
</template>

<script>
import Category from './Category.vue'
import Product from './Product.vue'
import Order from './Order.vue'
import Client from './Client.vue'
import Merchant from './Merchant.vue'
import Hotel from './Hotel.vue'
import Hotelorders from './HotelOrders'
import {mapState,mapActions} from 'vuex'
import userApi from '../api/user'

export default {
    name: 'admin',
    components: {
        Category,
        Product,
        Order,
        Client,
        Merchant,
        Hotel,
        Hotelorders
    },
    data(){
        return {
            currentTab: 'Category',
            tabs: ['Category','Merchant', 'Product', 'Hotel', 'Order', 'Client', 'Hotel Orders']
        }
    },
    mounted(){
        if(this.token.length > 0){
            userApi.checkJwt(this.token).then(()=>{}).catch(()=>{
                this.logout()
                console.log('jwt out of date')
                this.$message({
                    message: 'Your session is expired, need to login again',
                    type: 'warning'
                });
                this.setView({
                    view: 'login'
                })
            })
        }
    },
    computed: {
        ...mapState('client', ['token']),
        currentComponent: function(){
            return this.currentTab.toLowerCase().replace(' ', '')
        }
    },
    methods: {
        ...mapActions('client',[
            'logout',
            'setView',
            'checkout'
        ]),
    }
}
</script>

<style>

</style>