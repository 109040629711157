<template>
  <div id="product">
      <el-card>
          <div slot="header" style="text-align:right;">
            <el-button type="danger" @click="createProductClick">Create Product</el-button>
          </div>
          <el-container>
              <el-aside width="200px" style="background-color:#E4E7ED;">
                  <h3>Categories</h3>
                  <el-divider></el-divider>
                  <el-menu default-active="0" style="background-color:#E4E7ED;" @select="categorySelect">
                    <el-menu-item index="0">All</el-menu-item>
                    <el-menu-item  v-for="category in categories" :key="category.ID" :index="category.ID.toString()">{{category.name}}</el-menu-item>
                  </el-menu>
              </el-aside>
              <el-main>
                  <el-table :data="products">
                      <el-table-column prop="name" label="Name"></el-table-column>
                      <el-table-column prop="category_id" label="Category">
                          <template slot-scope="scope">
                              <div v-for="item in categories" :key="item.ID" v-show="item.ID == scope.row.category_id">{{item.name}}</div>
                          </template>
                      </el-table-column>
                      <el-table-column prop="merchant_id" label="Merchant">
                          <template slot-scope="scope">
                              <div v-for="item in merchants" :key="item.ID" v-show="item.ID == scope.row.merchant_id">{{item.name}}</div>
                          </template>
                      </el-table-column>
                      <el-table-column prop="skus" label="Price">
                          <template slot-scope="scope">
                              <div v-for="item in scope.row.skus" :key="item.ID">
                                  <span>{{item.name}} - ${{(item.price/100.0).toFixed(2)}}</span>
                              </div>
                          </template>
                      </el-table-column>
                      <el-table-column prop="skus" label="Member Price">
                          <template slot-scope="scope">
                              <div v-for="item in scope.row.skus" :key="item.ID">
                                  <span>{{item.name}} - ${{(item.member_price/100.0).toFixed(2)}}</span>
                              </div>
                          </template>
                      </el-table-column>
                      <el-table-column prop="image" label="Image" width="100px">
                          <template slot-scope="scope">
                              <el-image style="width:80px; height:80px;" :src="scope.row.image"></el-image>
                          </template>
                      </el-table-column>
                      <el-table-column prop="enable" label="Enable" width="80px">
                          <template slot-scope="scope">
                              <el-switch v-model="scope.row.enable" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
                          </template>
                      </el-table-column>
                      <el-table-column label="Actions" width="160px">
                          <template slot-scope="scope">
                              <el-button type="primary" size="small" @click="editProductClick(scope.row)" style="margin-right:10px;">Edit</el-button>
                              <el-popconfirm confirm-button-text="Delete" @confirm="deleteProductClick(scope.row)" cancel-button-text="Cancel" icon="el-icon-info" icon-color="red" :title="'Delete Category ' + scope.row.name + '?'">
                                <el-button slot="reference" type="danger" size="small">Delete</el-button>
                              </el-popconfirm>
                          </template>
                      </el-table-column>
                  </el-table>
                  <br/>
                  <el-pagination :page-size="pageSize" :current-page="pageIndex" :total="productCount" @current-change="handlePageChange" background></el-pagination>
              </el-main>
          </el-container>
      </el-card>
       <el-drawer :title="drawerTitle" :visible.sync="showDrawer" direction="rtl" style="text-align:left;" size="680px" :before-close="handleClose" :show-close="false" ref="drawer">
           <el-form :model="selectProduct" ref="productForm"  style="padding:10px;" label-width="160px" :rules="productFormRules">
             <el-form-item label="Name" prop="name">
                 <el-input v-model="selectProduct.name" placeholder=""></el-input>
             </el-form-item>
             <el-form-item label="Merchant" prop="merchant_id">
                 <el-select v-model="selectProduct.merchant_id">
                     <el-option v-for="merchant in merchants" :key="merchant.ID" :label="merchant.name" :value="merchant.ID"></el-option>
                 </el-select>
             </el-form-item>
             <el-form-item label="Category" prop="category_id">
                 <el-select v-model="selectProduct.category_id" placeholder="">
                   <el-option v-for="category in categories" :key="category.ID" :label="category.name" :value="category.ID"></el-option>
                 </el-select>
             </el-form-item>
             <el-form-item label="Image" prop="image">
                 <!--<el-image style="width:100px; height:100px;" :src="selectProduct.image"></el-image>-->
                 <net-image v-for="(item,index) in selectProduct.images" :key="index" :item="item" @deleteImage="deleteImage"></net-image>
                 <el-upload action="" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="fileUpload">
                   <el-button size="small" type="primary" :loading="fileLoading">Upload</el-button>
                   <div slot="tip" class="el-upload__tip">jpg/png file only，file size below 2MB</div>
                 </el-upload>
             </el-form-item>
             <el-form-item label="Price" prop="skus">
                 <el-popover placement="bottom" trigger="click" v-model="addSkuVisible">
                     <el-form :inline="true" :model="skuForm">
                     <el-form-item label="SKU Name">
                         <el-input v-model="skuForm.name" placeholder=""></el-input>
                     </el-form-item>
                     <el-form-item label="Price">
                         <el-input v-model="skuForm.price" type="number" placeholder=""></el-input>
                     </el-form-item>
                     <el-form-item label="Member Price">
                         <el-input v-model="skuForm.member_price" type="number" placeholder=""></el-input>
                     </el-form-item>
                     <el-form-item >
                         <el-button type="primary" size="small" @click="addSku">Save</el-button>
                     </el-form-item>
                    </el-form>
                    <el-button type="text" size="small" slot="reference">Add Price</el-button>
                 </el-popover>
                 <el-table :data="selectProduct.skus">
                   <el-table-column label="Name" prop="name"></el-table-column>
                   <el-table-column label="Price" prop="price"></el-table-column>
                   <el-table-column label="Member Price" prop="member_price"></el-table-column>
                   <el-table-column label="Actions">
                       <template slot-scope="scope">
                           <el-popover placement="right" trigger="click" v-model="scope.row.show">
                                <el-form :inline="true" :model="skuForm">
                                <el-form-item label="Name">
                                    <el-input v-model="scope.row.name" placeholder=""></el-input>
                                </el-form-item>
                                <el-form-item label="Price">
                                    <el-input v-model="scope.row.price" type="number" placeholder=""></el-input>
                                </el-form-item>
                                <el-form-item label="Member Price">
                                    <el-input v-model="scope.row.member_price" type="number" placeholder=""></el-input>
                                </el-form-item>
                                <el-form-item >
                                    <el-button type="primary" size="small" @click="editSku(scope.row)">Save</el-button>
                                </el-form-item>
                                </el-form>
                                <el-button type="text" size="small" slot="reference">Edit</el-button>
                            </el-popover>
                            <el-button type="text" style="margin-left:5px;" size="small" @click="removeSku(scope.row)">Delete</el-button>
                       </template>
                   </el-table-column>
                 </el-table>
             </el-form-item>
             <el-form-item label="Mini Collection Date">
                 <el-input v-model="selectProduct.min_collection_date" type="number" placeholder=""></el-input>
             </el-form-item>
             <el-form-item label="Enable">
                 <el-switch v-model="selectProduct.enable" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
             </el-form-item>
           </el-form>
          <div style="padding:10px;">
            <el-button @click="cancelForm" style="width:48%">Cancel</el-button>
            <el-button type="primary" style="width:48%" @click="saveProductClick" :loading="loading">{{ loading ? 'submit ...' : 'Save' }}</el-button>
          </div>
      </el-drawer>
  </div>
</template>

<script>
import aliossApi from '../api/alioss'
import {mapState, mapActions} from 'vuex'
import _ from 'lodash'
import NetImage from './items/NetImage.vue'

export default {
    name: 'product',
    components: {
        NetImage
    },
    data(){
        var validImage = (rule, value, callback)=>{
            if(value === ''){
                callback(new Error('image required'))
            } else {
                callback()
            }
        }
        var validPrice = (rule, value, callback)=>{
            if(value.length == 0) {
                callback(new Error('Price required'))
            } else {
                callback()
            }
        }
        return {
            loading: false,
            fileLoading: false,
            addSkuVisible: false,
            action: '',
            showDrawer: false,
            drawerTitle: '',
            selectProduct: {
                ID: 0,
                category_id: 0,
                merchant_id: 0,
                enable: true,
                min_collection_date: 1,
                image: '',
                name: '',
                skus: [],
                images: [],
                CreatedAt: ''
            },
            productFormRules:{
                name: [
                    { required: true, message: 'name required', trigger: 'blur' },
                ],
                category_id: [
                    { required: true, message: 'category required', trigger: 'change' },
                ],
                merchant_id: [
                    { required: true, message: 'merchant required', trigger: 'change' },
                ],
                image: [
                    { validator: validImage, trigger: 'change' },
                ],
                skus: [
                    {validator: validPrice, trigger: 'change'},
                ]
            },
            skuForm:{
                ID: 0,
                name: '',
                price: 0,
                member_price: 0
            }
        }
    },
    computed:{
        ...mapState('client', [
            'token'
        ]),
        ...mapState('category', [
            'categories'
        ]),
        ...mapState('merchant', [
            'merchants'
        ]),
        ...mapState('product', [
            'products',
            'productCount',
            'pageSize',
            'pageIndex',
            'activeCategoryIndex'
        ])
    },
    mounted(){
        console.log('product mounted')
        this.initData()
    },
    methods: {
        ...mapActions('product', [
            'getProducts',
            'getProductsByCategory',
            'createProduct',
            'setPageIndex',
            'setActiveCategory',
            'updateProduct',
            'deleteProduct'
        ]),
        ...mapActions('category', [
            'getCategoryList'
        ]),
        ...mapActions('merchant', [
            'getMerchantList'
        ]),
        beforeAvatarUpload(file) {
            console.log(file)
            const isJPG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG && !isPNG) {
              this.$message.error('JPG/PNG only!');
            }
            if (!isLt2M) {
              this.$message.error('file size must less than 2MB!');
            }
            return (isJPG || isPNG) && isLt2M;
        },
        fileUpload(options){
            this.fileLoading = true 
            console.log(options)
            var self = this
            console.log(self.selectProduct)
            aliossApi.uploadFile(options.file, this.token).then(imageUrl=>{
                this.fileLoading = false
                if(self.selectProduct.images.length === 0){
                    self.selectProduct.image = imageUrl
                }
                self.selectProduct.images.push({
                    ID:0,
                    ts: new Date().getTime(),
                    product_id:self.selectProduct.ID,
                    image: imageUrl
                })
                console.log(self.selectProduct)
                self.$forceUpdate()
            }).catch(()=>{
                this.fileLoading = false
            })
        },
        deleteImage(item){
            var index = -1
            for(var i=0; i<this.selectProduct.images.length;i++){
                if(this.selectProduct.images[i].ts === item.ts){
                    index = i
                    break
                }
            }
            if(index > -1){
                this.selectProduct.images.splice(index, 1)
                if(this.selectProduct.images.length > 0){
                    this.selectProduct.image = this.selectProduct.images[0].image
                } else {
                    this.selectProduct.image = ''
                }
            }
            console.log(this.selectProduct.images)
            this.$forceUpdate()
        },
        addSku(){
            var sku = {
                ID: new Date().getTime(),
                name: this.skuForm.name,
                price: this.skuForm.price,
                member_price: this.skuForm.member_price
            }
            this.skuForm.name = ''
            this.skuForm.price = 0
            this.skuForm.member_price = 0
            this.addSkuVisible = false
            this.selectProduct.skus.push(sku)
            console.log(this.selectProduct)
        },
        editSku(row){
            this.selectProduct.skus.forEach(sku=>{
                console.log(row, sku)
                if(sku.ID == row.ID){
                    sku.name = row.name
                    sku.price = row.price
                    sku.member_price = row.member_price
                }
            })
            row.show = false
        },
        removeSku(row){
            console.log(row)
            var index = 0
            for(var i=0; i < this.selectProduct.skus.length; i++){
                if(this.selectProduct.skus[i].ID == row.ID){
                    index = i
                    break
                }
            }
            this.selectProduct.skus.splice(index, 1)
        },
        initData(){
            if(this.categories.length == 0){
                this.getCategoryList()
            }
            if(this.merchants.length == 0){
                this.getMerchantList()
            }
            this.getProducts()
        },
        handlePageChange(val){
            console.log(val)
            this.setPageIndex({
                pageIndex: val
            })
        },
        categorySelect(index, indexPath){
            console.log(index, indexPath)
            this.setActiveCategory({
                index: index
            })
        },
        createProductClick(){
            this.resetProductForm()
            this.showDrawer = true
            this.drawerTitle = "Create Product"
            this.action = 'create'
        },
        editProductClick(r){
            console.log(r)
            this.resetProductForm()
            var row = _.cloneDeep(r)
            this.selectProduct.ID = row.ID
            this.selectProduct.name = row.name
            this.selectProduct.image = row.image
            this.selectProduct.merchant_id = row.merchant_id
            this.selectProduct.category_id = row.category_id
            this.selectProduct.CreatedAt = row.CreatedAt
            this.selectProduct.enable = row.enable
            this.selectProduct.min_collection_date = row.min_collection_date
            this.selectProduct.skus = row.skus
            this.selectProduct.skus.forEach(sku=>{
                sku.price = (sku.price / 100.0).toFixed(2)
                sku.member_price = (sku.member_price / 100.0).toFixed(2)
            })
            this.selectProduct.images = row.images
            this.showDrawer = true 
            this.drawerTitle = "Edit Product"
            this.action = 'edit'
        },
        deleteProductClick(row){
            this.loading = true
            this.deleteProduct({
                id: row.ID
            }).then(()=>{
                this.loading = false
            }).catch(()=>{
                this.loading = false
            })
        },
        saveProductClick(){
            this.$refs['productForm'].validate((valid)=>{
                if(valid){
                    if(this.action === 'create'){
                        this.loading = true
                        this.saveNewProduct()
                    } else if (this.action === 'edit'){
                        this.loading = true
                        this.saveUpdateProduct()
                    }
                } else {
                    return false
                }
            })
            
        },
        saveNewProduct(){
            this.createProduct({
                product: this.selectProduct,
            }).then(()=>{
                this.$message({
                    message: 'save successfuly!',
                    type: 'success'
                })
                this.resetProductForm()
                this.loading = false
                this.showDrawer = false
            }).catch(()=>{
                this.loading = false
            })
        },
        saveUpdateProduct(){
            console.log(this.selectProduct)
           
            this.updateProduct({
                product: this.selectProduct
            }).then(()=>{
                this.$message({
                    message: 'save successfuly!',
                    type: 'success'
                })
                this.resetProductForm()
                this.loading = false
                this.showDrawer = false
            }).catch(()=>{
                this.loading = false
            })
        },
        handleClose(done){
            done()
        },
        cancelForm() {
            this.loading = false;
            this.showDrawer = false;
        },
        resetProductForm(){
            this.selectProduct = {
                ID: 0,
                merchant_id: 0,
                category_id: 0,
                enable: true,
                min_collection_date: 1,
                image: '',
                name: '',
                skus: [],
                images: [],
                created_at: ''
            }
        }
    }
}
</script>

<style>

</style>