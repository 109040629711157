<template>
  <div id="hotelOrdres">
      <el-form :inline="true">
        <el-form-item label="By">
            <el-select v-model="searchBy" placeholder="">
              <el-option v-for="(item, index) in searchByItems" :key="index" :label="item" :value="index"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="=">
            <el-input v-model="searchByValue" placeholder="" clearable></el-input>
        </el-form-item>
        <el-form-item label="Date">
            <el-date-picker value-format="yyyy-MM-dd" v-model="selectDate" placeholder="" type="date" clearable></el-date-picker>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="search">Search</el-button>
        </el-form-item>
    </el-form>
    <el-card v-for="book in bookings" :key="book.ID" style="background-color:#E4E7ED;">
        <el-row style="text-align:left;font-weight:bold;background-color:#E4E7ED;">
            <el-col :span="12" :xs="24">Order Number: {{book.ID}}</el-col>
            <el-col :span="12" :xs="0" style="text-align:right;">Date: {{createdDate(book.CreatedAt)}}</el-col>
            <el-col :span="12" :xs="24" :sm="0" :md="0" :xl="0" :lg="0" style="text-align:left;">Date: {{createdDate(book.CreatedAt)}}</el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row style="text-align:left;margin-top:0px;">
            <el-col :span="24"><span style="font-weight:bold;font-size:14px;">Hotel:</span> {{book.hotel_name}}</el-col> 
            <el-col :span="12" :xs="0" style="margin-top:10px;"><span style="font-weight:bold;font-size:14px;">Contact Number:</span> {{book.hotel_contact_number}}</el-col>
            <el-col :span="12" :xs="0" style="text-align:right;"><span style="font-weight:bold;font-size:14px;">Email:</span> {{book.hotel_contact_email}}</el-col>
            <el-col :span="12" :xs="24" :sm="0" :md="0" :xl="0" :lg="0" style="margin-top:10px;"><span style="font-weight:bold;font-size:14px;">Contact Number:</span> {{book.hotel_contact_number}}</el-col>
            <el-col :span="12" :xs="24" :sm="0" :md="0" :xl="0" :lg="0" style="text-align:left;margin-top:10px;"><span style="font-weight:bold;font-size:14px;">Email:</span> {{book.hotel_contact_email}}</el-col>
            <el-col :span="24" style="text-align:left;margin-top:10px;"><span style="font-weight:bold;font-size:14px;">Address:</span> {{book.hotel_address}}</el-col>
        </el-row>
        <el-row style="text-align:left;">
            <el-col :span="6" :xs="24" style="margin-top:10px;"><span style="font-weight:bold;font-size:14px;">Room:</span> {{book.room_name}}</el-col> 
            <el-col :span="6" :xs="0">.</el-col>
            <el-col :span="6" :xs="12" style="text-align:left;margin-top:10px;"><span style="font-weight:bold;font-size:14px;">From:</span> {{book.date_from}}</el-col>
            <el-col :span="6" :xs="12" style="text-align:right;margin-top:10px;"><span style="font-weight:bold;font-size:14px;">To:</span> {{book.date_to}}</el-col>
        </el-row>
        <el-row style="text-align:left;margin-top:10px;">
            <el-col :span="6" :xs="24"><span style="font-weight:bold;font-size:14px;">Room Type:</span> {{book.room_type_price_name}}</el-col>
            <el-col :span="6">&nbsp; .</el-col>
            <el-col :span="6" :xs="24" style="text-align:left;"><span style="font-weight:bold;font-size:14px;">Weekday Price(S$):</span> {{book.room_type_week_day_price}}</el-col> 
            <el-col :span="6" :xs="0" style="text-align:right;"><span style="font-weight:bold;font-size:14px;">Weekend Price(S$):</span> {{book.room_type_week_end_price}}</el-col>
            <el-col :span="6" :xs="24" :sm="0" :md="0" :xl="0" :lg="0" style="text-align:left;margin-top:10px;"><span style="font-weight:bold;font-size:14px;">Weekend Price(S$):</span> {{book.room_type_week_end_price}}</el-col>
        </el-row>
    </el-card>
    <br/>
    <el-pagination background layout="prev, pager, next" @current-change="changeCurrentPage" :total="count" :page-size="pageSize" :current-page="pageIndex">
    </el-pagination>
  </div>
</template>

<script>
import hotelApi from '../api/hotel'
import util from '../js/util'
import {mapState} from 'vuex'
export default {
    name: 'hotelorders',
    data () {
        return {
            searchBy: '',
            searchByValue: '',
            searchByItems: ['Hotel Name', 'Client Name', 'Client Email'],
            selectDate: '',
            pageIndex: 1,
            pageSize: 20,
            count: 0,
            bookings:[]
        }
    },
    computed: {
        ...mapState('client', [
            'token'
        ])
    },
    methods: {
        createdDate(createdAt){
            var date = new Date(createdAt)
            return util.dateFormat("YYYY-mm-dd HH:MM:SS", date)
        },
        search(){
            var hotel_name = ''
            if (this.searchBy === 'Hotel Name') {
                hotel_name = this.searchByValue
            }
            var client_name = ''
            if (this.searchBy === 'Client Name') {
                client_name = this.searchByValue
            }
            var client_email = ''
            if (this.searchBy === 'Client Email') {
                client_email = this.searchByValue
            }
            hotelApi.bookingList(hotel_name, client_name, client_email, this.selectDate, this.pageIndex, this.pageSize, this.token).then(resp=>{
                this.bookings = resp.items
                this.count = resp.count
            })
        },
        changeCurrentPage(val){
            this.pageIndex = val
            this.search()
        }
    }
}
</script>

<style>

</style>