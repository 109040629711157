<template>
  <div id="login" style="max-width:1024px; margin: 20px auto; background-color:#fff;padding:20px; border:1px solid #DCDFE6">
      <el-container>
          <el-header style="background-color:#E4E7ED; height:48px; border-radius:5px; line-height:48px; text-align:left; font-size:18px;">
              <el-link type="primary" :underline="false" style="font-size:18px;" @click="backToIndexClick">Home</el-link>
              <el-link type="info" :underline="false" style="margin: 0px 5px; font-size:18px;">/</el-link>
              <el-link type="info" :underline="false" style="font-size:18px;">
                  <span v-if="hideRigster">Register</span>
                  <span v-else>Login</span>
              </el-link>
         </el-header>
         <el-main style="padding:0px;margin-top:20px;">
             <transition name="el-zoom-in-center">
                <el-card v-show="hideLogin" shadow="hover" style="max-width:580px;margin:0 auto;">
                    <el-row>
                        <el-col :span="24" :xs="24" style="padding:0px 20px 0px 0px;">
                            <el-form :model="loginForm" :rules="loginRules" ref="loginForm">
                                <el-form-item label="Email" prop="email">
                                    <el-input type="email" v-model="loginForm.email"></el-input>
                                </el-form-item>
                                <el-form-item label="Password" prop="password">
                                    <el-input type="password" v-model="loginForm.password"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submitLoginForm">Login</el-button>
                                    <el-button type="text" @click="showHideLoginAndRegister">Go To Register</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                </el-card>
                </transition>
                <transition name="el-zoom-in-center">
                <el-card v-show="hideRigster" shadow="hover" style="max-width:480px;margin:0 auto;">
                    <el-form :model="registerForm" :rules="registerRules" ref="registerForm">
                        <el-form-item label="Invite Code (Not Required)" prop="invite_code">
                            <el-input v-model="registerForm.invite_code"></el-input>
                        </el-form-item>
                        <el-form-item label="Name" prop="name">
                            <el-input v-model="registerForm.name"></el-input>
                        </el-form-item>
                        <el-form-item label="Email" prop="email">
                            <el-input type="email" v-model="registerForm.email"></el-input>
                        </el-form-item>
                        <el-form-item label="Contact No" prop="contact_no">
                            <el-input type="email" v-model="registerForm.contact_no">
                                <template slot="prepend">+65</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="Company Name" prop="company_name">
                            <el-input v-model="registerForm.company_name"></el-input>
                        </el-form-item>
                        <el-form-item label="Location" prop="location_id">
                            <el-select v-model="registerForm.location_id" placeholder="choose location">
                                <el-option v-for="item in locations" :key="item.ID" :label="item.name" :value="item.ID"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Password" prop="password">
                            <el-input type="password" v-model="registerForm.password"></el-input>
                        </el-form-item>
                        <el-form-item label="Check Password" prop="check_password">
                            <el-input type="password" v-model="registerForm.check_password"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitRegisterForm">Register</el-button>
                            <el-button type="text" @click="showHideLoginAndRegister">Go to Login</el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </transition>
         </el-main>
      </el-container>
      
  </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
import userApi from '../api/user'

export default {
    name: 'login',
    data(){
        var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('please input password'));
        } else {
          if (this.registerForm.checkPass !== '') {
            this.$refs.registerForm.validateField('check_password');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('please input password again'));
        } else if (value !== this.registerForm.password) {
          callback(new Error('password not match!'));
        } else {
          callback();
        }
      };
        return {
            loginScanTimer: '',
            loginScanCode: '',
            hideLogin: true,
            hideRigster: false,
            loginForm: {
                email: '',
                password: ''
            },
            loginRules:{
                email: [
                    {type: 'email', required: true, trigger: 'blur'}
                ],
                password: [
                    {required: true, trigger: 'blur'},
                    {min: 6, trigger: 'blur'}
                ]
            },
            registerForm: {
                invite_code: '',
                name: '',
                contact_no: '',
                company_name: '',
                location_id: '',
                location: null,
                email: '',
                password: '',
                check_password: ''
            },
            registerRules:{
                name: [
                    {required: true, trigger: 'blur'},
                ],
                contact_no: [
                    {required: true, trigger: 'blur'},
                ],
                 company_name: [
                    {required: true, trigger: 'blur'},
                ],
                location_id: [
                    {required: true, trigger: 'change'},
                ],
                email: [
                    {type: 'email', required: true, trigger: 'blur'}
                ],
                password: [
                    { validator: validatePass, trigger: 'blur' },
                     {min: 6, trigger: 'blur'}
                ],
                check_password: [
                    { validator: validatePass2, trigger: 'blur' },
                     {min: 6, trigger: 'blur'}
                ]
            },
        }
    },
    computed:{
        ...mapState('util', [
            'locations'
        ]),
        mainStyle(){
            if(this.hideRigster){
                return "position:relative;height:820px;"
            }
            return "position:relative;height:420px;"
        },
        loginScanCodeText() {
            return "type=login&id=" + this.loginScanCode
        }
    },
    created(){
        this.getLocations()
        // this.getLoginScanCode()
    },
    methods: {
        ...mapActions('client', [
            'login',
            'register',
            'setView',
            'appLogin'
        ]),
        ...mapActions('util', [
            'getLocations'
        ]),
        backToIndexClick(){
            this.setView({
                view: 'index'
            })
        },
        getLoginScanCode(){
            userApi.getLoginScanCode().then(resp=>{
                this.loginScanCode = resp
            })
            this.loginScanTimer = setTimeout(this.queryLoginScanCode, 2000)
        },
        queryLoginScanCode() {
            userApi.queryLoginScanCode(this.loginScanCode).then(resp=>{
                console.log(resp)
                clearTimeout(this.loginScanTimer)
                this.appLogin(resp)
                this.$message({
                    message: 'login successfully',
                    type: 'success'
                })

            }).catch(()=>{
                clearTimeout(this.loginScanTimer)
                this.loginScanTimer = setTimeout(this.queryLoginScanCode, 2000)
                console.log('retry check login scan code', this.loginScanCode)
            })
        },
        submitLoginForm(){
            this.$refs['loginForm'].validate((valid)=>{
                if(valid){
                    console.log('login valid')
                    let sha256 = require('js-sha256')
                    this.loginForm.password = sha256(this.loginForm.password)
                    this.login(this.loginForm).then(()=>{
                        this.$message({
                            message: 'login successfully',
                            type: 'success'
                        })
                    }).catch(()=>{
                        this.$message.error('email or password not match, please try again')
                        this.loginForm.password = ''
                    })
                } else {
                    return false;
                }
            })
        },
        submitRegisterForm(){
            this.$refs['registerForm'].validate((valid)=>{
                if(valid){
                    console.log('register valid')
                    let sha256 = require('js-sha256')
                    this.registerForm.password = sha256(this.registerForm.password)
                    this.registerForm.check_password = this.registerForm.password
                    this.register(this.registerForm).then(()=>{
                        this.$message({
                            message: 'register successfully',
                            type: 'success'
                        })
                    }).catch((result)=>{
                        console.log('11')
                        console.log(result)
                        console.log(result.message)
                        if (result.message === 'USED_CODE') {
                            this.$message.error('Invite Code has been used!')
                        } else {
                            this.$message.error('email or mobile is already exist, please change your email and try again')
                        }
                        
                        this.registerForm.password = ''
                        this.registerForm.check_password = ''
                    })
                } else {
                    return false
                }
            })
        },
        showHideLoginAndRegister(){
            this.hideLogin = !this.hideLogin
            this.hideRigster = !this.hideRigster
            if(!this.hideLogin) {
                clearTimeout(this.loginScanTimer)
            } else {
                clearTimeout(this.loginScanTimer)
                this.loginScanTimer = setTimeout(this.queryLoginScanCode, 2000)
            }
        }
    }
}
</script>

<style>

</style>