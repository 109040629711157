<template>
  <div id="goodItem" style="margin:10px 0px 5px 0px;">
    <el-card v-show="showLarge" style="width:180px;margin:0 auto;" :body-style="{ padding: '0px', width: '100%' }">
        <el-image :src="item.image" style="width:180px; height:180px;" fit="fit" :preview-src-list="previewImageList"></el-image>
        <div style="padding: 14px;text-align:left;">
            <span style="font-size:16px;font-weight:400;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;height:36px;">{{item.name}}</span>
            <span style="font-size:12px;color:#909399;">{{item.merchant.name}}</span>
            <span v-show="isMember" style="display:block;padding:8px 0px;font-size:18px;color:#F56C6C;font-weight:bold;">{{singleMemberPrice}}<span style="margin-left:5px;font-weight:normal;color:#409EFF;text-decoration:line-through;font-size:12px;">{{singlePrice}}</span></span>
            <span v-show="!isMember" style="display:block;padding:8px 0px;"><span style="font-size:18px;font-weight:bold;color:#409EFF;">{{singlePrice}}</span><span style="margin-left:5px;font-size:14px;color:#F56C6C;text-decoration:line-through;">{{singleMemberPrice}}</span></span>
            <template v-if="item.skus.length > 1">
            <el-popover placement="top" trigger="click" width="240">
                <el-row v-for="sku in item.skus" :key="sku.ID" style="margin-top:10px;border-bottom:1px solid grey;padding-bottom:10px;">
                    <el-col :span="10"><div style="line-height:30px;">{{sku.name}}</div></el-col>
                    <el-col :span="8" style="line-height:30px;">
                        <div v-show="isMember">
                            <span style="color:#F56C6C;">${{(sku.member_price/100.0).toFixed(2)}}</span> <span style="color:#409EFF;font-size:12px;text-decoration:line-through">${{(sku.price/100.0).toFixed(2)}}</span>
                        </div>
                        <div v-show="!isMember">
                            <span style="color:#409EFF;">${{(sku.price/100.0).toFixed(2)}}</span><span style="margin-left:5px;font-size:12px;color:#F56C6C;text-decoration:line-through">${{(sku.member_price/100.0).toFixed(2)}}</span> 
                        </div>
                    </el-col>
                    <el-col :span="6"><el-button type="danger" size="small" @click="addToCartClick(sku)">Add</el-button></el-col>
                </el-row>
                <el-button slot="reference" type="primary" style="display:block; width:100%; margin-top: 5px;">Add to Cart</el-button>
            </el-popover>
            </template>
            <el-button v-else type="primary" style="display:block; width:100%; margin-top: 5px;" @click="addToCartClick(item.skus[0])">Add to Cart</el-button>
        </div>
    </el-card>
    <el-card v-show="!showLarge" :body-style="{padding: '0px'}">
        <el-row>
            <el-col :span="12">
                <el-image :src="item.image" style="width:180px; height:180px;" fit="fit" :preview-src-list="previewImageList"></el-image>
            </el-col>
            <el-col :span="12">
                <div style="padding: 14px;text-align:left;">
                    <span style="font-size:16px;font-weight:400;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;height:36px;">{{item.name}}</span>
                    <span style="font-size:12px;color:#909399;">{{item.merchant.name}}</span>
                    <span v-show="isMember" style="display:block;padding:8px 0px;font-size:18px;color:#F56C6C;font-weight:bold;">{{singleMemberPrice}}<span style="margin-left:5px;font-weight:normal;color:#409EFF;text-decoration:line-through;font-size:12px;">{{singlePrice}}</span></span>
                    <span v-show="!isMember" style="display:block;padding:8px 0px;"><span style="font-size:18px;font-weight:bold;color:#409EFF;">{{singlePrice}}</span><span style="margin-left:5px;font-size:14px;color:#F56C6C;text-decoration:line-through;">{{singleMemberPrice}}</span></span>
                    <template v-if="item.skus.length > 1">
                        <el-popover placement="top" trigger="click" width="220">
                            <el-row v-for="sku in item.skus" :key="sku.ID" style="margin-top:10px;border-bottom:1px solid grey;padding-bottom:10px;">
                                <el-col :span="9"><div style="line-height:30px;">{{sku.name}}</div></el-col>
                                <el-col :span="9" style="line-height:30px;color:#F56C6C;">
                                    <div v-show="isMember">
                                        <span style="color:#F56C6C;">${{(sku.member_price/100.0).toFixed(2)}}</span> <span style="color:#409EFF;font-size:12px;text-decoration:line-through">${{(sku.price/100.0).toFixed(2)}}</span>
                                    </div>
                                    <div v-show="!isMember">
                                        <span style="color:#409EFF;">${{(sku.price/100.0).toFixed(2)}}</span><span style="margin-left:5px;font-size:12px;color:#F56C6C;text-decoration:line-through;">${{(sku.member_price/100.0).toFixed(2)}}</span> 
                                    </div>
                                </el-col>
                                <el-col :span="6"><el-button type="danger" size="small" @click="addToCartClick(sku)">Add</el-button></el-col>
                            </el-row>
                            <el-button slot="reference" type="primary" style="display:block; width:100%; margin-top: 25px;">Add to Cart</el-button>
                        </el-popover>
                    </template>
                    <el-button v-else type="primary" style="display:block; width:100%; margin-top: 25px;" @click="addToCartClick(item.skus[0])">Add to Cart</el-button>
                </div>
            </el-col>
        </el-row>
    </el-card>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
    name: 'goodItem',
    props: ['item', 'view'],
    computed: {
        ...mapState('client', ['client']),
        isMember: function() {
            if (this.client === null) {
                return false
            }
            return this.client.is_member
        },
        showLarge: function(){
            return this.view === 'large'
        },
        previewImageList: function(){
            var images = []
            this.item.images.forEach(img=>{
                images.push(img.image)
            })
            return images
        },
        singleMemberPrice: function () {
            if(this.item.skus.length == 0){
                return "$999"
            }
            return '$' + (this.item.skus[0].member_price/100.0).toFixed(2)
        },
        singlePrice: function(){
            if(this.item.skus.length == 0){
                return "$999"
            }
            return '$' + (this.item.skus[0].price/100.0).toFixed(2)
        },
        price: function(){
            if(this.item.skus.length == 0){
                return "$999"
            }else if(this.item.skus.length == 1){
                return '$' + (this.item.skus[0].price/100.0).toFixed(2)
            } else {
                var prices = []
                this.item.skus.forEach(sku => {
                  if(prices.length == 0){
                      prices.push(sku.price)
                  } else {
                      var put = false
                      for(var i=0; i < prices.length; i++){
                          if(prices[i] > sku.price){
                              prices.splice(i, 0, sku.price)
                              put = true 
                              break
                          }
                      }
                      if(!put){
                          prices.push(sku.price)
                      }
                  }
                })
                return "$" + (prices[0]/100.0).toFixed(2) + " - $" + (prices[prices.length - 1]/100.0).toFixed(2)
            }
        }
    },
    methods:{
        ...mapActions('cart', [
            'addGood'
        ]),
        addToCartClick(sku){
            console.log(this.item)
            var good = {
                key: this.item.ID + '-' + sku.ID,
                product: this.item,
                sku:sku,
                amount: 1,
                sub_total: sku.price,
                sub_member_total: sku.member_price
            }
            this.addGood({
                good: good
            })
            this.$notify({
                title: 'Add to Cart',
                dangerouslyUseHTMLString: true,
                message: '<div style="display:-webkit-box;"><div style="margin-right:8px;"><image src="' + this.item.image +'" style="width:45px; height:45px;" fit="fit"></image></div><div><div>' + this.item.name + '</div><div>1 ' + sku.name + "</div></div></div>",
                type: 'success'
            })
        }
    }
}
</script>

<style>

</style>