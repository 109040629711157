<template>
  <div id="category">
    <el-card>
        <div slot="header" style="text-align:right;">
            <el-button type="danger" @click="createCategoryClick">Create Category</el-button>
        </div>
        <el-table :data="categories" v-loading="loading">
            <el-table-column label="Name" prop="name"></el-table-column>
            <el-table-column prop="enable" label="Enable">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.enable" active-color="#13ce66" inactive-color="#ff4949" disabled></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="Action" width="120">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small" style="margin-right:20px;">Edit</el-button>
                    <el-popconfirm confirm-button-text="Delete" @confirm="deleteCategoryClick(scope.row)" cancel-button-text="Cancel" icon="el-icon-info" icon-color="red" :title="'Delete Category ' + scope.row.name + '?'">
                    <el-button slot="reference" type="text" size="small">Delete</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
      </el-card>
      <el-drawer  :show-close="false" :title="drawerTitle" :visible.sync="showDrawer" direction="rtl" style="text-align:left;" :before-close="handleClose" ref="drawer">
          <el-form :model="editForm" style="padding:10px;" ref="editForm" :rules="rules">
              <el-form-item label="Name" prop="name">
                  <el-input v-model="editForm.name" placeholder=""></el-input>
              </el-form-item>
              <el-form-item label="Enable" prop="enable">
                  <el-switch v-model="editForm.enable"></el-switch>
              </el-form-item>
          </el-form>
          <div style="padding:10px;">
            <el-button @click="cancelForm" style="width:48%">Cancel</el-button>
            <el-button type="primary" style="width:48%" @click="saveButtonClick" :loading="loading">{{ loading ? 'submit ...' : 'Save' }}</el-button>
          </div>
      </el-drawer>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'

export default {
    name: 'category',
    data(){
        return {
            loading: false,
            action: '',
            showDrawer: false,
            drawerTitle: 'Create Category',
            editForm: {
                id: 0,
                name: '',
                enable: true
            },
            rules:{
                name: [
                    {required: true, messsge: 'name required', trigger: 'blur'}
                ]
            }
        }
    },
    computed:{
        ...mapState('client', [
            'token'
        ]),
        ...mapState('category', [
            'categories'
        ])
    },
    mounted(){
        console.log('category mounted')
        this.list()
    },
    methods:{
        ...mapActions('category', [
            'getCategoryList',
            'createCategory',
            'updateCategory',
            'deleteCategory'
        ]),
        list(){
            this.loading = true
            this.getCategoryList().then(()=>{
                this.loading = false
            }).catch(()=>{
                this.loading = false
            })
        },
        createCategoryClick(){
            this.showDrawer = true
            this.drawerTitle = "Create Category"
            this.action = 'create'
            this.editForm.name = ''
        },
        handleClick(row){
            console.log(row)
            this.showDrawer = true
            this.drawerTitle = "Modify Category"
            this.action = "edit"
            this.editForm.name = row.name
            this.editForm.enable = row.enable
            this.editForm.id = row.ID
            console.log(this.editForm)
        },
        deleteCategoryClick(row){
            console.log(row)
            this.deleteCategory({
                categoryId: row.ID
            }).then(()=>{
                this.$message({
                    message: 'delete successfuly',
                    type: 'success'
                })
            })
        },
        saveButtonClick(){
            this.$refs['editForm'].validate((valid)=>{
                if(valid){
                    if(this.action === 'create'){
                        this.createCategory({
                            name: this.editForm.name,
                            enable: this.editForm.enable
                        }).then(()=>{
                            this.$message({
                                message: 'Save successfuly',
                                type: 'success'
                            })
                            this.showDrawer = false
                        })
                    } else if(this.action === 'edit'){
                        this.updateCategory({
                            categoryId: this.editForm.id,
                            categoryName: this.editForm.name,
                            enable: this.editForm.enable
                        }).then(()=>{
                            this.$message({
                                message: 'Save successfuly',
                                type: 'success'
                            })
                            this.showDrawer = false
                        })
                    }
                } else {
                    return false
                }
            })
        },
        handleClose(done){
            done()
        },
        cancelForm() {
            this.loading = false;
            this.showDrawer = false;
        }
    }
}
</script>

<style>

</style>