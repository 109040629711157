<template>
  <div id="clientOrderItem" style="padding: 18px 20px; margin-top:8px; background-color:#F2F6FC;">
    <div style="display:-webkit-box;border-bottom:1px solid #DCDFE6;padding-bottom:8px;">
      <div>{{item.ID}}</div>
      <div style="-webkit-box-flex:1;"></div>
      <div>{{createdDate}}</div>
    </div>
    <div style="display:-webkit-box;margin-top:8px;border-bottom:1px solid #DCDFE6;">
      <div style="display:-webkit-box; -webkit-box-flex:1;overflow-x:auto;">
        <div v-for="good in item.items" :key="good.ID">
          <el-image  style="width: 60px; height: 60px; margin:8px 8px 8px 0px;" :src="good.product_image"></el-image>
        </div>
      </div>
      <div style="width:100px; text-align:right; color:#F56C6C; font-size:18px; line-height:80px;">${{totalPrice}}</div>
    </div>
    <div style="display:-webkit-box;margin-top:8px;">
      <div><el-button type="text" @click="showDetail=!showDetail">Show Detail</el-button></div>
      <div style="-webkit-box-flex:1;"></div>
      <div style="line-height:30px;color:#F56C6C;">{{item.status}}</div>
    </div>
    <div style="display:-webkit-box;" v-show="showDetail">
      <div id="orderContent" style="-webkit-box-flex:1; background-color:#fff;">
        <div style="font-size:14px;border-bottom:1px solid #F2F6FC;padding-bottom:8px;padding-top:8px;padding-left:8px;text-align:left;">Order Content</div>
        <el-card shadow="never" v-for="(deliver, index) in item.delivers" :key="index">
          <div slot="header" style="text-align:left;font-size:14px;margin-top:5px;font-weight:bold;">{{deliver.merchant_name}}</div>
          <div v-for="good in item.items" v-show="good.merchant_id == deliver.merchant_id" :key="good.ID" style="display:-webkit-box;padding: 10px 20px;border-bottom: 1px solid #F2F6FC;font-size:12px;">
            <el-image :src="good.product_image" style="width:50px;height:50px;"></el-image>
            <div style="margin-left:20px;text-align:left;-webkit-box-flex:1;">
              <div style="font-size:14px;">{{good.product_name}}</div>
              <div style="font-size:12px;color:#909399;">{{good.category_name}}</div>
              <div style="margin-top:10px;">${{(good.sku_price/100.0).toFixed(2)}} For 1 {{good.sku_name}}</div>
            </div>
            <div style="width:120px;text-align:right;">
              {{good.amount}}
            </div>
            <div style="text-align:right;width:120px;">
              ${{(good.sub_total/100.0).toFixed(2)}}
            </div>
          </div>
          <el-divider></el-divider>
          <div style="font-size:14px;text-align:right;margin-right:20px;">Goods Fee: <span style="display:inline-block;width:48px;">${{(subTotalForMerchant(deliver.merchant_id) / 100.0).toFixed(2)}}</span></div>
          <div style="font-size:14px;text-align:right;margin-right:20px;">Deliver Fee: <span style="display:inline-block;width:48px;">${{(deliver.fee / 100.0).toFixed(2)}}</span></div>
          <div style="font-size:14px;text-align:right;margin-right:20px;">Sub Total: <span style="display:inline-block;width:48px;">${{((subTotalForMerchant(deliver.merchant_id) + deliver.fee) / 100.0).toFixed(2)}}</span></div>
        </el-card>
      </div>
      <div style="margin-left:8px;">
        <div style="text-align:left;background-color:#fff;">
           <div style="font-size:14px;border-bottom:1px solid #F2F6FC;padding:8px;text-align:left;">Shipping Address</div>
           <div class="address-item2">{{item.shipping.name}}</div>
           <div class="address-item2">{{item.shipping.contact_no}}</div>
           <div class="address-item2">{{item.shipping.office}}</div>
           <div class="address-item2">{{item.shipping.address}}</div>
           <div class="address-item2">Singapore, {{item.shipping.postal_code}}</div>
       </div>
        <div style="background-color:#fff;margin-top:8px;text-align:left;">
          <div style="font-size:14px;border-bottom:1px solid #F2F6FC;padding:8px;text-align:left;">Expected Shipping Date</div>
          <div class="address-item2">{{item.shipping.expected_shipping_date}} {{item.shipping.expected_shipping_time}}</div>
        </div>
       <div style="background-color:#fff;margin-top:8px;">
        <div style="font-size:14px;border-bottom:1px solid #F2F6FC;padding-bottom:8px;padding-top:8px;padding-left:8px;text-align:left;">Order Status</div>
        <div v-for="status in item.order_statues" :key="status.ID" style="font-size:12px;text-align:left;margin-top:8px;padding-left:8px;margin-bottom8px;">
          <div style="font-size:14px;font-weight:bold;padding-bottom:8px;">{{status.status}}<span style="font-size:12px;font-weight:normal;"> - {{getDate(status.CreatedAt)}}</span></div>
        </div>
      </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import util from '../js/util'
import {mapState} from 'vuex'

export default {
    name: 'clientOrderItem',
    props:['item'],
    data(){
      return {
        showDetail: false,
      }
    },
    computed: {
      ...mapState('client', [
        'client'
      ]),      
      createdDate: function(){
        var date = new Date(this.item.CreatedAt)
        return util.dateFormat("YYYY-mm-dd HH:MM:SS", date)
      },
      totalPrice: function(){
        return this.item.total/100.0
      }
    },
    methods: {
      getDate(dateStr){
        var date = new Date(dateStr)
        return util.dateFormat("YYYY-mm-dd HH:MM:SS", date)
      },
      subTotalForMerchant(id){
        var total = 0
        for(var i = 0; i < this.item.items.length; i++){
          if(this.item.items[i].merchant_id === id){
            total += this.item.items[i].sub_total
          }
        }
        return total
      }
    }
}
</script>

<style>
.address-item2{
    padding: 4px 8px;
    font-size: 12px;
}
</style>