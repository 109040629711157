<template>
  <el-main id="index" style="max-width:1024px; margin: 0px auto; background-color:#fff;padding:0px; border:1px solid #DCDFE6">
    <el-row>
        <el-col :span="24" :xs="0">
            <div style="margin:20px;padding: 0px 20px;background-color:#E4E7ED;border-radius:5px; line-height:48px; text-align:left; font-size:18px;">
                <el-link type="primary" :underline="false" style="font-size:18px;">Home</el-link>
                <el-link type="info" :underline="false" style="margin: 0px 5px; font-size:18px;">/</el-link>
                <el-link type="info" :underline="false" style="font-size:18px;">{{selectCategory.name}}</el-link>
                <el-link type="primary" :underline="false" style="font-size:18px; float:right;" @click="gotoHotel">Booking Hotel</el-link>
            </div>
        </el-col>
        <el-col :span="24" :xs="24" :sm="0" :md="0" :lg="0" :xl="0" style="padding: 0px 20px;background-color:#E4E7ED; height:48px; border-radius:5px; text-align:left; font-size:18px;">
            <el-drawer :visible.sync="drawer" direction="ltr" :with-header="false" size="50%">
                <ul style="list-style:none;padding-left:20px;">
                   <li>
                       <el-link :underline="false"  type="text" :class="selectCategory.ID == 0 ? 'link-active' : 'link-normal'" @click="categoryClick({ID:0, name:'All Categories'})">All Categories</el-link>
                   </li>
                   <li v-for="item in categories" :key="item.ID">
                       <el-link type="primary" :underline="false" :class="selectCategory.ID == item.ID ? 'link-active' : 'link-normal'" @click="categoryClick(item)">{{item.name}}</el-link>
                   </li>
               </ul>
            </el-drawer>
            <div style="display:-webkit-box;">
                <div><el-button type="text" @click="drawer = true" size="medium">Show Result For : {{selectCategory.name}} <i class="el-icon-arrow-down"></i></el-button></div>
                <div style="-webkit-box-flex:1;line-height:36px;text-align:right;"><el-link type="primary" :underline="false"  @click="gotoHotel">Hotel</el-link></div>
            </div>
        </el-col>
    </el-row>
    <el-row>
       <el-col :span="5" :xs="0" style="padding:0px 10px 0px 20px;">
           <div style="font-size:18px; text-align:left; margin:20px 0px;">Show Result For</div>
           <div style="border:1px solid #E4E7ED; background-color:#EBEEF5; text-align:left;">
               <ul style="list-style:none;padding-left:20px;">
                   <li>
                       <el-link :underline="false"  type="text" :class="selectCategory.ID == 0 ? 'link-active' : 'link-normal'" @click="categoryClick({ID:0, name:'All Categories'})">All Categories</el-link>
                   </li>
                   <li v-for="item in categories" :key="item.ID">
                       <el-link type="primary" :underline="false" :class="selectCategory.ID == item.ID ? 'link-active' : 'link-normal'" @click="categoryClick(item)">{{item.name}}</el-link>
                   </li>
               </ul>
           </div>
       </el-col>
       <el-col :span="19" :xs="24" style="padding:0px 10px;">
           <el-row>
               <el-col :span="24" :xs="0">
                    <div style="text-align:left; font-size:32px; padding-top:10px;">{{selectCategory.name}}</div>
                    <div style="text-align:right; font-size:12px;"><span style="color:#F56C6C;">red price - member price</span></div>
                    <div style="text-align:right; font-size:12px;"><span style="color:#409EFF;"><span style="text-decoration:line-through;">blue price</span> - none-member price</span></div>
                    <el-divider></el-divider>
               </el-col>
               <el-col :span="24" :xs="24" :sm="0" :md="0" :lg="0" :xl="0">
                   <div style="display:-webkit-box;">
                        <div style="text-align:left; font-size:12px;-webkit-box-flex:1;"><span style="color:#F56C6C;">red price - member price</span></div>
                        <div style="text-align:right; font-size:12px;-webkit-box-flex:1;"><span style="color:#409EFF;"><span style="text-decoration:line-through;">blue price</span> - none-member price</span></div>
                   </div>
               </el-col>
           </el-row>
           <el-row>
               <el-col :span="6" :xs="0" v-for="item in products" :key="item.ID">
                <good-item :item="item" :view="large"></good-item>
               </el-col>
               <el-col :span="24" :xs="24" :sm="0" :md="0" :lg="0" :xl="0" v-for="(item, index) in products" :key="index">
                   <good-item :item="item" :view="small"></good-item>
               </el-col>
           </el-row>
           <!--<div class="flex-div">
               <good-item v-for="item in products" :key="item.ID" :item="item"></good-item>
               <div style="width:180px; height:0px;"></div>
               <div style="width:180px; height:0px;"></div>
               <div style="width:180px; height:0px;"></div>
           </div>-->
           <br/>
           <el-pagination background layout="prev, pager, next" :page-size="pageSize" :current-page="pageIndex" :total="productCount" @current-change="handlePageChange"></el-pagination>
       </el-col>
    </el-row>
  </el-main>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import GoodItem from './GoodItem.vue'

export default {
    name: 'index',
    components: {
        GoodItem
    },
    data(){
        return {
            drawer: false,
            large: 'large',
            small: 'small',
            selectCategory: {
                ID: 0,
                name: 'All Categories'
            }
        }
    },
    computed: {
        ...mapState('client', ['client']),
        ...mapState('category', [
            'categories'
        ]),
        ...mapState('product', [
            'products',
            'productCount',
            'pageSize',
            'pageIndex'
        ]),
        isMember: function(){
            if(this.client == null){
                return false
            }
            return this.client.is_member
        }
    },
    created(){
        this.init()
    },
    methods: {
        ...mapActions('client', [
            'setView'
        ]),
        ...mapActions('category', [
            'getActiveCategoryList'
        ]),
        ...mapActions('product', [
            'getActiveProducts',
            'getActiveProductsByCategory',
            'setActiveCategory',
            'setActivePageIndex'
        ]),
        init(){
            this.getActiveCategoryList().then(()=>{
                if(this.selectCategory.ID == 0){
                    this.getActiveProducts()
                } else {
                    this.getActiveProductsByCategory()
                }
            })
        },
        categoryClick(item){
            this.drawer = false
            this.selectCategory.ID = item.ID
            this.selectCategory.name = item.name
            this.setActiveCategory({
                index: item.ID
            })
        },
        handlePageChange(val){
            this.setActivePageIndex({
                pageIndex: val
            })
        },
        handleCommand(command) {
            console.log(command)
            if(command === '0'){
                this.categoryClick({ID:0, name:'All Categories'})
            }
            var selectItem = null
            this.categories.forEach(item => {
                if(item.ID === command) {
                    selectItem = item
                }
            });
            if(selectItem !== null) {
                this.categoryClick(selectItem)
            }
        },
        gotoHotel(){
            window.location.reload()
            this.setView({
                view: 'HotelView'
            })
        }
    }
}
</script>

<style>
.link-active {
    color: #409EFF !important;
    font-size:16px !important;
    padding:12px 0px !important;
    font-weight: bold !important;
}
.link-normal {
    color: #409EFF !important;
    font-size:16px !important;
    padding:12px 0px !important;
    font-weight: normal !important;
}
.el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    font-size: 18px;
}
.el-icon-arrow-down {
    font-size: 12px;
}
.flex-div {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}
</style>