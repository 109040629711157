<template>
  <div id="hotel">
    <el-card>
      <div slot="header" style="text-align:right;">
        <el-button type="danger" @click="hotelCreate">Create Hotel</el-button>
      </div>  
      <el-container>
        <el-aside width="200px" style="background-color:#E4E7ED;">
          <h3>Hotels</h3>
          <el-divider></el-divider>
          <el-menu default-active="0" style="background-color:#E4E7ED;" @select="hotelSelect">
            <el-menu-item  v-for="hotel in hotels" :key="hotel.ID" :index="hotel.ID.toString()">{{hotel.name}}</el-menu-item>
          </el-menu>
        </el-aside>
        <el-main>
          <el-card>
            <div slot="header" style="font-size:22px;font-weight:bold;">
              {{selectHotel.name}}
              <el-button v-show="selectHotel.ID > 0" style="float: right; padding: 3px 0" type="text" @click="hotelEdit">Edit</el-button>
            </div>
            <div style="margin-top:20px;text-align:left;">
              <div style="display:-webkit-box">
                <el-image style="width:200px;height:200px;" v-for="image in selectHotel.images" :key="image.ID" :src="image.image"></el-image>
              </div>
            </div>
            <el-divider></el-divider>
            <el-row style="text-align:left;">
              <el-col :span="8"><span style="font-weight:bold">Merchant:</span> {{selectHotel.merchant? selectHotel.merchant.name : ''}}</el-col>
              <el-col :span="8"><span style="font-weight:bold">Contact Email:</span> {{selectHotel.email}}</el-col>
              <el-col :span="8"><span style="font-weight:bold">Contact Number:</span> {{selectHotel.contact_number}}</el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row style="text-align:left;margin-top:20px;">
              <el-col :span="24"><span style="font-weight:bold">Address:</span> {{selectHotel.address}}</el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row style="text-align:left;margin-top:20px;">
              <el-col :span="24"><span style="font-weight:bold">Validity:</span> {{selectHotel.validity}}</el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row style="text-align:left;margin-top:20px;">
              <el-col :span="24" style="font-weight:bold;">Privileges</el-col>
              <el-col :span="24" style="margin-top:10px;">
                <ul>
                  <li v-for="item in selectHotel.privileges" :key="item.ID">
                    {{item.text}}
                  </li>
                </ul>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row style="text-align:left;margin-top:20px;">
              <el-col :span="24" style="font-weight:bold;">Conditions</el-col>
              <el-col :span="24" style="margin-top:10px;">
                <ul>
                  <li v-for="item in selectHotel.conditions" :key="item.ID">
                    {{item.text}}
                  </li>
                </ul>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row style="text-align:left;margin-top:20px;">
              <el-col :span="24" style="font-weight:bold;">Inclusions</el-col>
              <el-col :span="24" style="margin-top:10px;">
                <ul>
                  <li v-for="item in selectHotel.inclusions" :key="item.ID">
                    {{item.text}}
                  </li>
                </ul>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row style="text-align:left;margin-top:20px;">
              <el-col :span="24" style="font-weight:bold;">Remarks</el-col>
              <el-col :span="24" style="margin-top:10px;">
                <ul>
                  <li v-for="item in selectHotel.remarks" :key="item.ID">
                    {{item.text}}
                  </li>
                </ul>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row style="text-align:left;margin-top:20px;">
              <el-col :span="24" style="font-weight:bold;">Cancel Policies</el-col>
              <el-col :span="24" style="margin-top:10px;">
                <ul>
                  <li v-for="item in selectHotel.cancel_policies" :key="item.ID">
                    {{item.text}}
                  </li>
                </ul>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row style="text-align:left;margin-top:20px;">
              <el-col :span="24" style="font-weight:bold;">Blackout Dates</el-col>
              <el-col :span="24" style="margin-top:10px;">
                <ul>
                  <li v-for="item in selectHotel.blackout_dates" :key="item.ID">
                    {{item.text}}
                  </li>
                </ul>
              </el-col>
            </el-row>
          </el-card>
          <el-card style="margin-top:20px;">
            <div slot="header" style="font-weight:bold;marign:20px 0px;text-align:left;">Rooms:</div>
            <el-card v-for="room in selectHotel.rooms" :key="room.ID" style="text-align:left;margin:20px;" shadow="hover">
              <div slot="header" style="text-align:left;">
                {{room.name}}
              </div>
              <div style="display:-webkit-box">
                <el-image style="width:200px;height:200px;" v-for="image in room.images" :key="image.ID" :src="image.image"></el-image>
              </div>
              <el-row style="margin-top:20px;">
                <el-col :span="24"><span style="font-weight:bold">Desc:</span>{{room.text}}</el-col>
              </el-row>
              <el-divider></el-divider>
              <div style="font-weight:bold;margin:20px 0px;">Room Types</div>
              <el-card v-for="roomType in room.room_type_prices" :key="roomType.ID" shadow="never">
                <el-row>
                  <el-col :span="24"><span style="font-weight:bold">Name:</span>{{roomType.room_type}}</el-col>
                  <el-col :span="24" style="margin-top:20px;"><span style="font-weight:bold">Desc:</span>{{roomType.text}}</el-col>
                  <el-col :span="12" style="margin-top:20px;"><span style="font-weight:bold">Week Day Price(S$):</span>{{roomType.week_day_price}}</el-col>
                  <el-col :span="12" style="margin-top:20px;"><span style="font-weight:bold">Week End Price(S$):</span>{{roomType.week_end_price}}</el-col>
                </el-row>
              </el-card>
            </el-card>
          </el-card>
        </el-main>
      </el-container>
    </el-card>
    <el-dialog :visible.sync="showHotelDialog" fullscreen>
      <h3>Create Hotel</h3>
      <el-card shadow="never">
        <el-form :model="selectHotel" label-width="120px" label-position="left">
          <el-form-item label="Name">
            <el-input v-model="selectHotel.name" placeholder="input hotel name"></el-input>
          </el-form-item>
          <el-form-item label="Address">
            <el-input v-model="selectHotel.address" placeholder="input address"></el-input>
          </el-form-item>
          <el-form-item label="Contact Number">
            <el-input v-model="selectHotel.contact_number" placeholder="contact number"></el-input>
          </el-form-item>
          <el-form-item label="Contact Email">
            <el-input v-model="selectHotel.email" placeholder="contact email"></el-input>
          </el-form-item>
          <el-form-item label="Validate To" style="text-align:left;">
            <el-date-picker v-model="selectHotel.validity" type="date" value-format="yyyy-MM-dd" placeholder="validate to"></el-date-picker>
          </el-form-item>
          <el-form-item label="Merchant" style="text-align:left;">
            <el-select v-model="selectHotel.merchant_id">
                <el-option v-for="merchant in merchants" :key="merchant.ID" :label="merchant.name" :value="merchant.ID"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Images" style="text-align:left">
            <div v-for="(image, index) in selectHotel.images" :key="index" style="width:200px;">
              <div style="text-align:right;position:relative;top:20px;left:10px;z-index:99;">
                <el-button style="width:25px;height:25px;padding:5px;" type="danger" icon="el-icon-close" circle @click="deleteHotelImage(image)"></el-button>
              </div>
              <el-image style="width:200px;height:200px;" :src="image.image"></el-image>
            </div>
            <el-upload action="" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="fileUpload">
              <el-button size="small" type="primary" :loading="fileLoading">Upload</el-button>
              <div slot="tip" class="el-upload__tip">jpg/png file only，file size below 2MB</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="Privileges" style="text-align:left;">
              <el-button size="small" type="primary" @click="addPrivilige">New Privilege</el-button>
              <hotel-content-item @deleteItem="deltePrivilige" v-for="(item, index) in selectHotel.privileges" :key="index" :item="item"></hotel-content-item>
          </el-form-item>
          <el-form-item label="Conditions" style="text-align:left;">
              <el-button size="small" type="primary" @click="addCondition">New Condition</el-button>
              <hotel-content-item @deleteItem="deleteCondition" v-for="(item, index) in selectHotel.conditions" :key="index" :item="item"></hotel-content-item>
          </el-form-item>
          <el-form-item label="Inclusions" style="text-align:left;">
              <el-button size="small" type="primary" @click="addInclusion">New Inclusion</el-button>
              <hotel-content-item @deleteItem="deleteInclusion" v-for="(item, index) in selectHotel.inclusions" :key="index" :item="item"></hotel-content-item>
          </el-form-item>
          <el-form-item label="Remarks" style="text-align:left;">
              <el-button size="small" type="primary" @click="addRemark">New Remark</el-button>
              <hotel-content-item @deleteItem="deleteRemark" v-for="(item, index) in selectHotel.remarks" :key="index" :item="item"></hotel-content-item>
          </el-form-item>
          <el-form-item label="Cancel Policies" style="text-align:left;">
              <el-button size="small" type="primary" @click="addCancelPolicy">New Cancel Policy</el-button>
              <hotel-content-item @deleteItem="deleteCancelPolicy" v-for="(item, index) in selectHotel.cancel_policies" :key="index" :item="item"></hotel-content-item>
          </el-form-item>
          <el-form-item label="Blackout Dates" style="text-align:left;">
              <el-button size="small" type="primary" @click="addBlackoutDate">New Blackout Date</el-button>
              <hotel-content-item @deleteItem="deleteBlackoutDate" v-for="(item, index) in selectHotel.blackout_dates" :key="index" :item="item"></hotel-content-item>
          </el-form-item>
           <el-form-item label="Enable" style="text-align:left;">
                 <el-switch v-model="selectHotel.enable" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
             </el-form-item>
        </el-form>
        <el-card shadow="never">
            <div slot="header" style="text-align:left">Rooms
                <el-button style="float:right;margin-top:-7px;" size="small" type="danger" @click="createRoomClick">Create Room</el-button>
            </div>
            <hotel-room-item v-for="(item, index) in selectHotel.rooms" :key="index" :item="item" @removeRoom="removeRoomClick"></hotel-room-item>
        </el-card>
        <div style="padding:14px;float:right;">
          <el-button type="primary" @click="saveEdit" :loading="btnLoading">Save</el-button>
          <el-button @click="cancelEdit">Cancel</el-button>
        </div>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import aliossApi from '../api/alioss'
import hotelApi from '../api/hotel'
import HotelContentItem from './items/HotelContentItem.vue'
import HotelRoomItem from './items/HotelRoomItem.vue'

export default {
  name: "hotel",
  components: {
      HotelContentItem,
      HotelRoomItem
  },
  data () {
    return {
      showHotelDialog: false,
      fileLoading: false,
      btnLoading: false,
      hotels: [],
      selectHotel: {
        ID: 0,
        name: '',
        address: '',
        contact_number: '',
        email: '',
        validity: '',
        merchant_id: 0,
        privileges: [],
        conditions: [],
        inclusions: [],
        remarks: [],
        cancel_policies: [],
        blackout_dates: [],
        images: [],
        enable: true,
        rooms: []
      }
    }
  },
  computed: {
    ...mapState('merchant', [
        'merchants'
    ]),
    ...mapState('client', [
        'token'
    ])
  },
  mounted () {
    this.getHotelList()
  },
  methods: {
    ...mapActions('merchant', [
        'getMerchantList'    
    ]),
    beforeAvatarUpload(file) {
         console.log(file)
         const isJPG = file.type === 'image/jpeg';
         const isPNG = file.type === 'image/png';
         const isLt2M = file.size / 1024 / 1024 < 2;

         if (!isJPG && !isPNG) {
           this.$message.error('JPG/PNG only!');
         }
         if (!isLt2M) {
           this.$message.error('file size must less than 2MB!');
         }
         return (isJPG || isPNG) && isLt2M;
    },
    fileUpload(options){
        this.fileLoading = true 
        console.log(options)
        aliossApi.uploadFile(options.file, this.token).then(imageUrl=>{
            this.fileLoading = false
            this.selectHotel.images.push({
              ID: 0, 
              hotel_id: this.selectHotel.ID, 
              image: imageUrl
            })
        }).catch(()=>{
            this.fileLoading = false
        })
    },
    addPrivilige(){
        this.selectHotel.privileges.push({
            ts: new Date().getTime(),
            hotel_id: this.selectHotel.ID,
            text: ''
        })
    },
    deltePrivilige(item){
        this.selectHotel.privileges = this.deleteContentItem(item, this.selectHotel.privileges)
    },
    addCondition(){
        this.selectHotel.conditions.push({
            ts: new Date().getTime(),
            hotel_id: this.selectHotel.ID,
            text: ''
        })
    },
    deleteCondition(item){
        this.selectHotel.conditions = this.deleteContentItem(item, this.selectHotel.conditions)
    },
    addInclusion(){
        this.selectHotel.inclusions.push({
            ts: new Date().getTime(),
            hotel_id: this.selectHotel.ID,
            text: ''
        })
    },
    deleteInclusion(item){
        this.selectHotel.inclusions = this.deleteContentItem(item, this.selectHotel.inclusions)
    },
    addRemark(){
        this.selectHotel.remarks.push({
            ts: new Date().getTime(),
            hotel_id: this.selectHotel.ID,
            text: ''
        })
    },
    deleteRemark(item){
        this.selectHotel.remarks = this.deleteContentItem(item, this.selectHotel.remarks)
    },
    addCancelPolicy(){
        this.selectHotel.cancel_policies.push({
            ts: new Date().getTime(),
            hotel_id: this.selectHotel.ID,
            text: ''
        })
    },
    deleteCancelPolicy(item){
        this.selectHotel.cancel_policies = this.deleteContentItem(item, this.selectHotel.cancel_policies)
    },
    addBlackoutDate(){
        this.selectHotel.blackout_dates.push({
            ts: new Date().getTime(),
            hotel_id: this.selectHotel.ID,
            text: ''
        })
    },
    deleteBlackoutDate(item){
        this.selectHotel.blackout_dates = this.deleteContentItem(item, this.selectHotel.blackout_dates)
    },
    deleteContentItem(item, items){
        console.log(item)
        var index = -1
        for(var i=0; i < items.length; i++){
            if(items[i].ts === item.ts){
                index = i
                break
            }
        }
        if(index > -1) {
            items.splice(index, 1)
        }
        return items
    },
    createRoomClick(){
        this.selectHotel.rooms.push({
            ts: new Date().getTime(),
            hotel_id: this.selectHotel.ID,
            name: '',
            text: '',
            images: [],
            room_type_prices: []
        })
    },
    removeRoomClick(room){
        var index = -1
        for(var i=0; i < this.selectHotel.rooms.length; i++){
            if(this.selectHotel.rooms[i].ts === room.ts){
                index = i
                break
            }
        }
        if(index > -1){
            this.selectHotel.rooms.splice(index, 1)
        }
    },
    saveEdit() {
      console.log(this.selectHotel)
      this.btnLoading = true
      if(this.selectHotel.ID === 0) {
        hotelApi.create(this.selectHotel, this.token).then(resp=>{
          console.log(resp)
          this.$message({
            type:'success',
            message: 'Create Hotel Success'
          })
          this.showHotelDialog = false
          this.btnLoading = false
        }).catch(()=>{
          this.btnLoading = false
        })
      } else {
        hotelApi.update(this.selectHotel, this.token).then(resp=>{
          console.log(resp)
          this.$message({
            type:'success',
            message: 'Save Hotel Success'
          })
          this.showHotelDialog = false
          this.btnLoading = false
        }).catch(()=>{
          this.btnLoading = false
        })
      }
    },
    cancelEdit() {
      this.showHotelDialog = false;
      this.selectHotel = {
        ID: 0,
        name: '',
        address: '',
        contact_number: '',
        email: '',
        validity: '',
        merchant_id: 0,
        privileges: [],
        conditions: [],
        inclusions: [],
        remarks: [],
        cancel_policies: [],
        blackout_dates: [],
        images: [],
        enable: true,
        rooms: []
      }
    },
    getHotelList() {
      hotelApi.list().then(resp=>{
        this.hotels = resp
      })
    },
    hotelSelect(index, indexPath){
      console.log(index, indexPath)
      this.hotels.forEach(h=>{
        if(h.ID.toString() === index) {
          this.selectHotel = h
        }
      })
      console.log(this.selectHotel)
    },
    hotelEdit() {
      this.showHotelDialog = true
    },
    hotelCreate() {
      this.cancelEdit()
      this.showHotelDialog = true
    },
    deleteHotelImage(image){
      var index = -1
      for (var i=0; i<this.selectHotel.images.length; i++){
        if(this.selectHotel.images[i].ts === image.ts) {
          index = i
          break
        }
      }
      if (index > -1){
        this.selectHotel.images.splice(index, 1)
      }
    }
  }
}
</script>

<style>
.input-div {
  display: -webkit-box;
}
</style>