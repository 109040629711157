<template>
  <div id="hotelContentItem" style="padding:8px 0px;">
      <el-input v-model="item.text">
        <template slot="append">
            <el-button type="danger" size="small" @click="deleteClick">Delete</el-button>
        </template>
      </el-input>
      
  </div>
</template>

<script>
export default {
  name: 'hotelContentItem',
  props: ['item'],
  methods: {
      deleteClick(){
          this.$emit('deleteItem', this.item)
      }
  }
}
</script>

<style>

</style>